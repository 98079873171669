import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FolioDetailService } from '../../services/foliodetail.service';
import { FolioService } from '../../services/folio.service';
import { WmvService } from '../../services/wmv.service';
import { Folio, FolioArticle, Button } from '../folio.model';
import { Headers } from '@angular/http';
import myGlobals = require('./../../globals');

@Component({
  moduleId: module.id,
  selector: 'app-foliodetail',
  templateUrl: 'foliodetail.component.html',
  providers: [ FolioDetailService, FolioService, WmvService, DatePipe ],
  styleUrls: ['foliodetail.component.scss']
})

export class FoliodetailComponent implements OnInit {

  public _folio: Folio;
  public _allFolios: Array<Folio>;
  public _FolioArticle:FolioArticle;
  public newRow:any = {};
  public newButtons: Array<Button>;
  public _myGlobals;
  public missingArticleSectionDetail = false;

  public testSelection:Array<any>;
  filteredLanguages: any[];
  filteredDocumentTypes: any[];
  filteredFormats: any[];
  public alerts:Array<Object> = [];
  public msgs:Array<Object> = [];

  public headers: Headers;
  private sub: any;
  public inputFields: Array<any>

  @Output() myEvent = new EventEmitter();

  languages: string[] = ['Deutsch','Englisch','Französisch'];
  documentTypes: string[] = ['Broschüre','Flyer','Merkblatt','Prospekt','Florpost','Technisches Blatt','Detailblatt','Technische Broschüre','Broschüre lang DIN','Technische Information','Montageanleitung','Anleitungen','Magazin','Revisionsanleitung'];
  formats: string[] = ['A4', 'DIN Lang'];

  ngOnInit() {
    this.getAllFolioNames();
    this.sub = this.route.params.subscribe(params => {
      let name = params['name'];
      if (name !== 'new') {
        this.getFolioDetailFromService(name);
        this.generateFields(name);
      } else {
        this._folio.folioProductID = this._myGlobals.System + '.' + this._folio.folioName;
        this.generateFields(name);
      }
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  constructor(
    private _folioDetailService: FolioDetailService,
    private _folioService: FolioService,
    private _wmvService: WmvService,
    private _router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe
    )
  {
    this._folio = new Folio();
    this._folio.articles = [];
    this._folio.folioType = '';
    this._folio.folioName = '';
    this._folio.folioIsHighlight = '0';
    this._folio.STATUS = 'NEW';
    this._folio.version = 0;
    this._folio.folioDescription = '';
    this._myGlobals = myGlobals;
  }

  showWarnings() {
    this.msgs = [];
    for (let i = 0; i < this._folio.messages.length; i++) {
      this.msgs.push({severity: 'warn', summary: this._folio.messages[i].level + ' (' + this.datePipe.transform(this._folio.messages[i].timestamp, 'medium') + ')', detail: this._folio.messages[i].msg});
    }
  }

  toggleArticle(index) {
    this._folio.articles[index].hidden = !this._folio.articles[index].hidden
  }

  deleteWarnings() {
    this._folioDetailService.deleteFolioMessage(this._folio).subscribe(
      data => {
        var result:Object = data['0'].json;
        this._folio.messages = [];
        this.msgs = [];
      },
      err => {
        this.addErrorAlert(status);
      },
      () => {
        let name =  this.route.snapshot.params['name'];
        this.getFolioDetailFromService(name);
      }
    );
  }

  private generateFields(name){
    this.inputFields = [
     {name: 'Folio Produkt-ID *', identity: 'folioProductID', type: 'text', disabled: true, required: true },
     {name: 'Folio Name *', identity: 'folioName', type: 'text', disabled: name != 'new', filter: true, required: true},
     {name: 'Folio Titel *', identity: 'folioTitle', type: 'text', required: true},
     {name: 'Folio Kurzbeschreibung', identity: 'folioDescription', type: 'text', required: false},
     {name: 'Folio Sprache *', identity: 'folioLanguage', type: 'select', suggestions: this.filteredLanguages, required: true},
     {name: 'Folio Dokument Type *', identity: 'folioDocumentType', type: 'select', suggestions: this.filteredDocumentTypes, required: true},
     {name: 'Dokument Datum *', identity: 'documentDate', type: 'text', disabled: true, required: true},
    ];
  }

  onUpload(event, imageType) {
    let response = JSON.parse(event.xhr.responseText);
    if (imageType === 'indexTitleImage') {
      this._folio.indexTitleImage = response.assetPath+"?random="+Math.random();
    }
    if (imageType === 'folioCoverImage') {
      this._folio.folioCoverImage = response.assetPath+"?random="+Math.random();
    }
  }

  onBeforeSend(event) {
    this.headers = new Headers();
    let token = localStorage.getItem('auth_token');
    event.xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  }

  private onCancel() {
    let link = ['/folio'];
    this._router.navigate(link);
  }

  private onDelete() {
    this.deleteFolio(this._folio)
    this.onCancel()
  }

  public setDocumentDate() {
    if (this._folio.folioDocumentType !== 'folder') {
      for (let i = 0; i < this._folio.articles.length; i ++) {
        for (let j = 0; j < this._folio.articles[i].sections.length; j ++) {
          if (
            this._folio.articles[i].sections[j].date &&
            this._folio.articles[i].sections[j].date !== '' &&
            new Date(this._folio.articles[i].sections[j].date).getTime() > new Date(this._folio.documentDate).getTime()
          ) {
            this._folio.documentDate = this._folio.articles[i].sections[j].date;
          }
        }
      }
    }
  }

  public updateSections(event) {
    if (event && event.sections && event.sections.length > 0 && typeof event.articleIndex === 'number') {
      this._folio.articles[event.articleIndex].sections = event.sections
    }
  }

  public refreshDocument(event) {
    let type = this._folio.folioDocumentType !== 'final' ? 'pdf' : 'html';

    if (type === 'pdf') {
      this._wmvService.returnDocumentDetail(event.WMVID, type).subscribe(
        data => {
          const articleDate = data['0'].json.ausgabe;
          const letzte_upload_aenderung = data['0'].json.letzte_upload_aenderung;
          const pageCount = data['0'].json.pagecount;
          this.newRow.pageCount = pageCount;
          let set = '';
          if (event.sectionNr !== undefined && event.sectionNr !== '') {
            set = event.sectionNr;
          }
          if (event.sectionNr === undefined || event.sectionNr === '') {
            this._folio.articles[event.index].date = articleDate;
          } else {
            this._folio.articles[event.sectionNr].sections[event.index].date = articleDate;
            this._folio.articles[event.sectionNr].sections[event.index].letzte_upload_aenderung = letzte_upload_aenderung;
            this._folio.articles[event.sectionNr].sections[event.index].pageCount = pageCount;
          }
          this.setDocumentDate();
          if (set === '') {
            this.getFolioDocument(event.index, set, event.WMVID.toString());
          } else {
            this.getFolioDocument(event.sectionNr, event.index, event.WMVID.toString());
          }
        },
        err => console.error(err)
      );
    }
  }

  private getFolioDocument(key:string, set:string, name:string) {
    let type = this._folio.folioDocumentType !== 'final' ? 'pdf' : 'html';
    this._wmvService.returnDocumentDetail(name, type).subscribe(
      data => {
        if (data['0'].json === null) {
          if (set !== '') {
            this._folio.articles[key].sections[set].invalid = true;
          } else {
            this._folio.articles[key].invalid = true;
          }
        } else {
          if (
            set !== '' &&
            this._folio.articles &&
            this._folio.articles[key] &&
            this._folio.articles[key].sections &&
            this._folio.articles[key].sections[set]
          ) {
            this._folio.articles[key].sections[set].invalid = false;
            if (
              !this._folio.articles[key].sections[set].letzte_upload_aenderung ||
              this._folio.articles[key].sections[set].letzte_upload_aenderung === '' ||
              new Date(this._folio.articles[key].sections[set].letzte_upload_aenderung) < new Date(data['0'].json.letzte_upload_aenderung)
            ) {
              this._folio.articles[key].sections[set].outdated = true;
            } else {
              this._folio.articles[key].sections[set].outdated = false;
            }
          } else if (
            this._folio.articles &&
            this._folio.articles[key]
          ) {
            this._folio.articles[key].invalid = false;
            if (
              !this._folio.articles[key].articleDate ||
              this._folio.articles[key].articleDate === '' ||
              new Date(this._folio.articles[key].letzte_upload_aenderung) < new Date(data['0'].json.letzte_upload_aenderung)
            ) {
              this._folio.articles[key].outdated = true;
            } else {
              this._folio.articles[key].outdated = false;
            }
          }
        }
      },
      err => console.error(err)
    );
  }

  private setFolioState(state:string) {
    switch (state) {
    case 'dpsupload':
      this._folio.STATUS = 'UPLOADING';
      break;
    case 'publish':
      this._folio.STATUS = 'IN_PUBLISHING_QUEUE';
      break;
    case 'unpublish':
      this._folio.STATUS = 'TO_DELETE';
      break;
    default:
      break;
    }
    if (
      state == 'dpsupload' ||
      state == 'publish' ||
      state == 'unpublish'
    ) {
      this.alerts.push({msg: 'Foliobearbeitung läuft', type: 'warning', closable: false, dismiss: 0});
      this.postFolioStatus(state);
    } else if (state == 'CREATE') {
      this.postFolioProduce();
    } else {
      this.putFolioStatus(state);
    }
  }

  private saveFolioDetail() {
    for (i = 0; i < this._folio.articles.length; i++) {
      if (this._folio.articles[i].sections) {
        for (let j = 0; j < this._folio.articles[i].sections.length; j++) {
          for (var key in this._folio.articles[i].sections[j]) {
            if (this._folio.articles[i].sections[j].hasOwnProperty(key)) {
              if (this._folio.articles[i].sections[j].WMVID !== '') {
                this._folio.articles[i].sections[j].sectionNumber = (j + 1) * 10;
              }
            }
          }
        }
      }
    }

    if (this._folio.folioType !== 'finalesFolio') {
      for (var i = 0; i < this._folio.articles.length; i++) {
        for (var key in this._folio.articles[i]) {
          if (this._folio.articles[i].hasOwnProperty(key)) {
            if (this._folio.articles[i].WMVID !== '') {
              this._folio.articles[i].articleName = this._folio.articles[i].WMVID + '.' + i * 10;
              this._folio.articles[i].articleNumber = i * 10;
            }
          }
        }
      }
    }

    let name = this.route.snapshot.params['name'];

    if (name === 'new') {
      this.postFolioDetail(this._folio.STATUS);
    } else {
      if (this._folio.STATUS !== 'NEW') {
        this._folio.STATUS = 'UPDATE';
      }
      this.putFolioDetail(this._folio.STATUS);
    }
  }

  private generateID() {
    let name = this.route.snapshot.params['name'];
    if (name === 'new') {
      this._folio.folioProductID = this._myGlobals.System + '.' + this._folio.folioName;
    }
  }

  private removeArticle(index:number) {
    // Check if the article that will be removed is the last article in the folio
    // If so, set status to 'new' because WMVID has to be changeable.
    if (this._folio.articles.length === 1) {
      this._folio.STATUS = 'NEW'
    }
    this._folio.articles.splice(index, 1);
  }

  public addSuccessAlert(status):void {
    let successMessage = '';
    if (this._myGlobals.statuses[status]) {
      successMessage = this._myGlobals.statuses[status].translation + ' erfolgreich';
    } else {
      successMessage = 'erfolgreich';
    }
    this.alerts.push({msg: successMessage, type: 'success', closable: true, dismiss: 3000});
  }

  public addErrorAlert(status, describtion=false):void {
    let errorMessage = '';
    if (describtion === false) {
      if (this._myGlobals.statuses[status]) {
        errorMessage = this._myGlobals.statuses[status].translation + ' fehlgeschlagen';
      } else {
        errorMessage = 'fehlgeschlagen';
      }
      this.alerts.push({msg: errorMessage, type: 'danger', closable: true, dismiss: 3000});
    } else if (describtion === true) {
      this.alerts.push({msg: status, type: 'danger', closable: true, dismiss: 3000});
    }
  }

  // loops all articles/sections to check if document exists in db
  public getAllFolioDocuments() {
    for (var key in this._folio.articles) {
      if (this._folio.articles.hasOwnProperty(key)) {
        if (this._folio.articles[key].sections) {
          for (var set in this._folio.articles[key].sections) {
            if (this._folio.articles[key].sections.hasOwnProperty(set)) {
              if (
                this._folio.articles[key].sections[set].WMVID !== undefined &&this._folio.articles[key].sections[set].WMVID !== ''
              ) {
                this.getFolioDocument(key, set, this._folio.articles[key].sections[set].WMVID);
              }
            }
          }
        } else {
          this._folio.articles[key].sections = []
        }
      }
    }
  }

  private getAllFolioNames() {
    this._folioService.returnFolioList().subscribe(
      data => { console.log('HTTP-Status: ', data['0'].status); this._allFolios =  data['0'].json},
      err => console.error(err),
      () => {
        console.log ('Ok got all folios: ', this._allFolios)
        this.setDocumentDate();
        this.folioCheck();
      }
    );
  }

  private getFolioDetailFromService(name:string) {
    this._folioDetailService.returnFolioDetail(name).subscribe(
      data => {
        this._folio = data['0'].json;
        if (this._folio.folioType !== 'finalesFolio') {
          this.getAllFolioDocuments();
        }
      },
      err => console.error(err),
      () => {
        if (this._folio.articles.length > 0 && this._folio.articles[0].sections && this._folio.articles[0].sections.length > 0) {
          this.newRow = {
            WMVID: this._folio.articles[0].sections[0].WMVID,
            printNumber: this._folio.articles[0].sections[0].printNumber,
            pageCount: this._folio.articles[0].sections[0].pageCount
          };
        }
      }
    );
  }

  private postFolioDetail(status) {
    this._folioDetailService.postFolioDetail(this._folio).subscribe(
      data => {
        var result:Object = data['0'].json;
        var httpStatus = data['0'].status;
        if (httpStatus === 200) {
          this.addSuccessAlert(status);
        } else {
          let describtion = false;
          this.addErrorAlert(result, describtion);
        }
      },
          err => {
            this.addErrorAlert(status);
          },
          () => {
            let link = ['/folio/' + this._folio.folioName];
        		this._router.navigate(link);
          }
      );
  }

  private putFolioDetail(status) {
    this._folioDetailService.putFolioDetail(this._folio).subscribe(
      data => {
        var result:Object = data['0'].json;
        var httpStatus = data['0'].status;
        if (httpStatus === 200) {
          this.addSuccessAlert(status);
        } else {
          let describtion = false;
          this.addErrorAlert(result, describtion);
        }
      },
        err => {
          this.addErrorAlert(status);

        },
        () => {
          let name =  this.route.snapshot.params['name'];
          this.getFolioDetailFromService(name);
        }
      );
  }

  private postFolioProduce() {
	  const status = 'CREATE';
    this._folioDetailService.postFolioProduce(this._folio).subscribe(
      data => {
        var result:Object = data['0'].json;
        var httpStatus = data['0'].status;
        if(httpStatus == 200){
          this.addSuccessAlert(status);
        }else{
          let describtion = false;
          this.addErrorAlert(result, describtion);
        }
      },
      err => {
        this.addErrorAlert(status);
      },
      () => {
        let name =  this.route.snapshot.params['name'];
        this.getFolioDetailFromService(name);
      }
    );
  }

  private putFolioStatus(status) {
    this._folioDetailService.putFolioStatus(this._folio, status).subscribe(
      data => {
        var result:Object = data['0'].json;
        var httpStatus = data['0'].status;
        if(httpStatus == 200){
          this.addSuccessAlert(status);
        }else{
          let describtion = false;
          this.addErrorAlert(result, describtion);
        }
      },
      err => {
        this.addErrorAlert(status);
      },
      () => {
        let name =  this.route.snapshot.params['name'];
        this.getFolioDetailFromService(name);
      }
    );
  }

  private postFolioStatus(status) {
    this._folioDetailService.postFolioStatus(this._folio, status).subscribe(
      data => {
        var httpStatus = data['0'].status;
        if (httpStatus == 200) {
          this.addSuccessAlert(status);
        } else {
          let describtion = false;
          this.addErrorAlert(status);
        }
      },
        err => {
          this.addErrorAlert(status);
        },
        () => {
          this.alerts = [];
          let name =  this.route.snapshot.params['name'];
          this.getFolioDetailFromService(name);
        }
      );
  }

  deleteFolio (folio: Folio) {
    this._folioService.deleteFolio(folio).subscribe(
      data => {
        console.log('Data returned from WebService: ', data['0'].json)
      },
      err => console.error(err),
      () => console.log('Deleted Folio')
    );
  }

  public firstRowFields: Array<any> = [
    {name: 'Dokumenttyp *', identity:'folioType', type: 'select',
      options:[
        {key: '', value: 'Bitte wählen', disabled: true},
        {key: 'SingleFolio', value: 'Single PDF Folio'},
        {key: 'Folder', value:'Ordner Folio'},
        {key: 'finalesFolio', value:'Finales Folio'}
      ]
    },
    {name: 'STATUS: ', identity:'STATUS', type: 'status', default: 'NEW'},
    {name: 'Version: ', identity:'version', type: 'text', default: '-'}
  ];

  filter(event, fieldType) {
    switch (fieldType) {
    case 'folioLanguage':
      this.filteredLanguages = [];
      for (let i = 0; i < this.languages.length; i++) {
        let language = this.languages[i];
        if (language.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
          this.filteredLanguages.push(language);
        }
      }
      break;
    case 'folioDocumentType':
      this.filteredDocumentTypes = [];
      for (let i = 0; i < this.documentTypes.length; i++) {
        let documentType = this.documentTypes[i];
        if(documentType.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
          this.filteredDocumentTypes.push(documentType);
        }
      }
      break;
    case 'folioFormats':
      this.filteredFormats = [];
      for (let i = 0; i < this.formats.length; i++) {
        let formats = this.formats[i];
        if (formats.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
          this.filteredFormats.push(formats);
        }
      }
      break;
    }
  }

  handleDropdownClick(event, fieldType) {
    switch(fieldType) {
    case 'folioLanguage':
      this.filteredLanguages = [];
      setTimeout(() => {
        this.filteredLanguages = this.languages;
      }, 100)
      break;
    case 'folioDocumentType':
      this.filteredDocumentTypes = [];
      setTimeout(() => {
        this.filteredDocumentTypes = this.documentTypes;
      }, 100)
      break;
    case 'folioFormats':
      this.filteredFormats = [];
      setTimeout(() => {
        this.filteredFormats = this.formats;
      }, 100)
      break;
    }
  }

  checkFolioType() {
    this._folio.articles = [];
    if (this._folio.folioType === 'Folder') {
      this._folio.folioDocumentType = 'Ordner';
    } else if (this._folio.folioDocumentType === 'Ordner') {
      this._folio.folioDocumentType = '';
    }
  }

  folioCheck() {
    const requiredFields = [
      'articleName',
      'articleTitle',
      'sectionTitle',
      'articlePageFrom',
      'pageFrom',
      'articlePageTo',
      'sections',
      'pageTo'
    ];

    this.missingArticleSectionDetail = this._folio.articles.filter((article) => {
      return (
        requiredFields.filter((value) =>
          value in article && !article[value]
        ).length !== 0 && article.articleNumber !== 999 && article.articleTitle !== 'Index'
      )
    }).length > 0;
  }

  public addArticle(theArticle:any) {
    let articleAmount = 0;
    if (!this._folio.articles) {
      this._folio.articles = []
    }
    if (this._folio.articles) {
      articleAmount = this._folio.articles.length;
    }
    if (this._folio.articles && this._folio.articles.length > 0 && this._folio.folioType !== 'Folder') {
      theArticle.sections = [{
        date: this._folio.articles[0].sections[0].date,
        letzte_upload_aenderung: this._folio.articles[0].sections[0].letzte_upload_aenderung,
        WMVID: this._folio.articles[0].sections[0].WMVID,
        printNumber: this._folio.articles[0].sections[0].printNumber,
        pageCount: this._folio.articles[0].sections[0].pageCount
      }]
      this._folio.articles.push(theArticle);
      this.newRow = {};
      if (
        this._folio.articles &&
        this._folio.articles[0].sections &&
        this._folio.articles[0].sections.length > 0
      ) {
        this.newRow = {
          date: this._folio.articles[0].sections[0].date,
          letzte_upload_aenderung: this._folio.articles[0].sections[0].letzte_upload_aenderung,
          WMVID: this._folio.articles[0].sections[0].WMVID,
          printNumber: this._folio.articles[0].sections[0].printNumber,
          pageCount: this._folio.articles[0].sections[0].pageCount
        };
      }
      this.myEvent.emit(true);
    } else {
      // Async data for 'letzte_upload_änderung' from wmvService
      this._wmvService.returnDocumentDetail(this.newRow.WMVID, 'pdf').subscribe(
        data => {
          theArticle.articleTitle = this.newRow.articleTitle
          theArticle.date = this.newRow.articleDate
          theArticle.sections = [{
            date: this.newRow.date,
            letzte_upload_aenderung: data['0'].json.letzte_upload_aenderung,
            WMVID: this.newRow.WMVID,
            printNumber: this.newRow.printNumber,
            pageCount: this.newRow.pageCount
          }]
          this._folio.documentDate = this.newRow.articleDate
          this._folio.articles.push(theArticle); //breaks folder folios right now
          this.newRow = {};
          if (
            this._folio.articles &&
            this._folio.articles[0].sections &&
            this._folio.articles[0].sections.length > 0 &&
            this._folio.folioType !== 'Folder'
          ) {
            this.newRow = {
              date: this._folio.articles[0].sections[0].date,
              letzte_upload_aenderung: this._folio.articles[0].sections[0].letzte_upload_aenderung,
              WMVID: this._folio.articles[0].sections[0].WMVID,
              printNumber: this._folio.articles[0].sections[0].printNumber,
              pageCount: this._folio.articles[0].sections[0].pageCount
            };
          }
          this.myEvent.emit(true);
        },
        err => console.error(err)
      )
    }
  }
}
