import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { DataTable } from 'primeng/primeng';
import { WmvService } from '../services/wmv.service';
import myGlobals = require('./../globals');

@Component({
  moduleId: module.id,
  selector: 'app-wmvlist',
  templateUrl: 'wmvlist.component.html',
  styleUrls: ['wmvlist.component.scss'],
  providers: [ WmvService ],
  inputs: ['single', 'selectedWmvs', 'edit', 'type', 'showUnusedDocuments']
})

export class WmvlistComponent{

  public _myGlobals = myGlobals;
  public _myGlobalsPDFstatusesLength: any;
  public selectedWmvs: Object[];
  public single: boolean;
  public edit: boolean;
  public showUnusedDocuments: boolean;
  public type: string;
  public chosenFilter: any = '';

  @Output() chosenDocument = new EventEmitter();
  @ViewChild('dt') dataTable: DataTable;

  listOfShownDocuments: Array<any>;
  listOfWmvs: Array<any>;
  listOfUnusedDocuments: Array<any>;

  constructor(
    private _wmvService: WmvService
  ) {
    if (!this.showUnusedDocuments) {
      this.returnWmvList();
      this.returnUnusedDocumentsList();
      this._myGlobalsPDFstatusesLength = Object.keys(this._myGlobals.PDFstatuses);
    } else {
      this.returnUnusedDocumentsList();
    }
  }

  ngOnChanges(changes: any) {
    if (changes.showUnusedDocuments) {
      this.toggleUnusedDocuments(changes.showUnusedDocuments)
    }
  }

  onFilter(e, dt) {
    localStorage.setItem('filters-wmvlist', JSON.stringify(e));
    this.dataTable = dt;
  }

  private toggleUnusedDocuments(showUnusedDocumentsChanges) {
    if (showUnusedDocumentsChanges.currentValue === true) {
      this.listOfShownDocuments = this.listOfUnusedDocuments;
    } else {
      this.listOfShownDocuments = this.listOfWmvs;
    }
  }

  public getSelectedFolios(){
    return this.selectedWmvs;
  }

  private refreshStatus() {
    this.returnWmvList();
  }

  private returnDocument(wmv: Object) {
    this.chosenDocument.next(wmv);
  }

  private resetFilters() {
    const options = document.querySelectorAll('.wmvlist-state-select-options')
    const types = document.querySelectorAll('.wmvlist-type-select-options')
    for (let i = 0, iMax = options.length; i < iMax; i += 1) {
      (<any>options[i]).selected = false
    }
    for (let i = 0, iMax = types.length; i < iMax; i += 1) {
      (<any>types[i]).selected = false
    }
    this.dataTable.reset();
    localStorage.removeItem('filters-wmvlist');
  }

  private toggleWmv(wmv: Object, event) {
    if (event.currentTarget.checked == true) {
      if (!this.single) {
        let i = this.selectedWmvs.length;
        this.selectedWmvs[i] = {
          'WMVID' : wmv['wmv_id'],
          'printNumber': wmv['drucknr'],
          'pageCount': wmv['pageCount'],
          'letzte_upload_aenderung': wmv['letzte_upload_aenderung'],
          'date': wmv['ausgabe']
        };
      } else if (this.single) {
        this.selectedWmvs['WMVID'] = wmv['wmv_id'];
        this.selectedWmvs['printNumber'] = wmv['drucknr'];
        this.selectedWmvs['pageCount'] = wmv['pagecount'];
        this.selectedWmvs['articleDate'] = wmv['ausgabe'];
        this.selectedWmvs['date'] = wmv['ausgabe'];
        this.selectedWmvs['letzte_upload_aenderung'] = wmv['letzte_upload_aenderung'];
      }
    } else if (event.currentTarget.checked == false) {
      if (!this.single) {
        let i;
        for (i = 0; i < this.selectedWmvs.length; i++) {
          if (this.selectedWmvs[i]['WMVID'] === wmv['wmv_id']) {
            this.selectedWmvs.splice(i , 1);
          }
        }
      } else if (this.single) {
        this.selectedWmvs['WMVID'] = undefined;
      }
    }
  }

  private inAssignements(wmv) {
    if(!this.single){
      var i;
      for (i = 0; i < this.selectedWmvs.length; i++) {
        if (this.selectedWmvs[i]['WMVID'] === wmv['wmv_id']) {
          return true;
        }
      }
      return false;
    } else if (this.single) {
      if (this.selectedWmvs['WMVID'] === wmv['wmv_id']) {
        return true;
      }
      return false;
    }
  }

  returnWmvList() {
    this._wmvService.returnWmvList().subscribe(
      data => {
        this.listOfWmvs = data['0'].json ;
        this.listOfShownDocuments = data['0'].json;
      },
      err => console.error(err),
      () => console.log('done loading documents')
    );
  }

  private getLastWord(words) {
    var n = words.split(' ');
    return n[n.length - 1];
  }

  private checkForAndApplyFilters() {
    let filters = localStorage.getItem('filters-wmvlist');
    if (filters) {
      for (const [key, val] of (<any>Object).entries(JSON.parse(filters).filters)) {
        const col = key
        let value = ''
        if (val && (<any>val).value && typeof (<any>val).value === 'string') {
          value = (<any>val).value
        } else if (val && (<any>val).value && Array.isArray((<any>val).value)) {
          value = (<any>val).value[0]
        }
        const mode = (val && (<any>val).matchMode) ? (<any>val).matchMode : ''
        this.dataTable.filter(value, col, mode);

        if (key === 'STATUS' && value !== '') {
          const stateOptions = document.querySelectorAll('.wmvlist-state-select-options')
          for (let i = 0, iMax = stateOptions.length; i < iMax; i += 1) {
            const optionValue = this.getLastWord((<any>stateOptions[i]).value)
            if (optionValue === value) {
              (<any>stateOptions[i]).selected = true
            }
          }
        }

        if (key === 'type' && value !== '') {
          const typeOptions = document.querySelectorAll('.wmvlist-type-select-options')
          for (let i = 0, iMax = typeOptions.length; i < iMax; i += 1) {
            const optionValue = this.getLastWord((<any>typeOptions[i]).value);
            if (optionValue === value) {
              (<any>typeOptions[i]).selected = true
            }
          }
        }
      }
    }
  }

  returnUnusedDocumentsList() {
    this._wmvService.returnUnsedDocuments().subscribe(
      data => {
        this.listOfUnusedDocuments = data['0'].json ;
      },
      err => console.error(err),
      () => {
        console.log('done loading documents without folios');
        this.checkForAndApplyFilters()
      }
    );
  }
}
