// login.component.ts
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './../services/user.service';

@Component({
  moduleId: module.id,
  selector: 'app-login',
  styleUrls: ['login.component.scss'],
  templateUrl: 'login.component.html'
})
export class LoginComponent {

  public alert:Object;

  constructor(private userService: UserService, private router: Router) {}

  onSubmit(email, password) {
    console.log("login attempt");
    this.userService.login(email.toLowerCase(), password).subscribe((result) => {
      if (result) {
        console.log("logged in");
        this.router.navigate(['']);
      }else{
        this.alert = {msg: 'Beim Login ist ein Fehler unterlaufen', type: 'danger', closable: true};
      }
    });
  }
}
