//include https://gist.github.com/blacksonic/7ac4ae4b000cd4fa7d678cc3bdb54de9#file-profile-service-ts-L13 to restrict all requests

import { Injectable } from '@angular/core';
import { Http, Response, Headers, Request, RequestMethod, RequestOptions } from '@angular/http';
import {AppConfiguration} from '../shared/app.configuration';
import { Router } from '@angular/router';
import  'rxjs/Rx';
import { Observable } from 'rxjs/Observable';

@Injectable()

export class RestService {

  public headers:Headers;
  public requestoptions:RequestOptions;
  public res: Object;
  public error: any;
  private token: string;

  constructor(
    public _http: Http,
    public router: Router
  ) {
    var appConfig:AppConfiguration = new AppConfiguration();
    this.headers = new Headers();
    this.initAuth();
  };

  public initAuth(){
    // console.log('initAuth called')
    this.token = localStorage.getItem('auth_token');
    this.headers.append('Authorization', 'Bearer '+this.token);
  }

  public GetRequest(url:string, contentType='application/json'): Observable<any> {
    this.headers.append('Content-Type', contentType);
    this.requestoptions = new RequestOptions({
      method: RequestMethod.Get,
      url: url,
      headers: this.headers

    });
    return this._http.request(new Request(this.requestoptions))
      .map((res: Response) => {
        if (res) {
          if(res.status < 200 || res.status >= 300) {
            throw new Error('This request has failed ' + res.status);
          } else {
            return [{ status: res.status, json: res.json() }]
          }
        }
      }).catch((error: Response | any) => {
        let errMsg: string;
        if (error.status == 401) {
          localStorage.removeItem('auth_token');
          this.router.navigate(['login']);
          errMsg = 'unauthorisiert';
        }
        return Observable.throw(errMsg);
      });
  }

  public PostRequest(url:string ,data:Object, contentType='application/json') {
    this.headers.append('Content-Type', contentType);
    this.requestoptions = new RequestOptions({
      method: RequestMethod.Post,
      url: url,
      headers: this.headers,
      body: JSON.stringify(data)
    })
    return this._http.request(new Request(this.requestoptions))
      .map((res: Response) => {
        if (res) {
          if(res.status < 200 || res.status >= 300) {
            throw new Error('This request has failed ' + res.status);
          } else {
          // return [{ status: res.status, json: res.json() }]
          return [{ status: res.status }]
          }
        }
      }).catch((error: Response | any) => {
        let errMsg: string;
        if(error.status == 401){
          localStorage.removeItem('auth_token');
          this.router.navigate(['login']);
          errMsg = 'unauthorisiert';
        }
        return Observable.throw(errMsg);
      });
  }

  public PutRequest(url:string ,data:Object, contentType='application/json') {
    this.headers.append('Content-Type', contentType);
    this.requestoptions = new RequestOptions({
      method: RequestMethod.Put,
      url: url,
      headers: this.headers,
      body: JSON.stringify(data)
    });
    return this._http.request(new Request(this.requestoptions))
      .map((res: Response) => {
        if (res) {
          if(res.status < 200 || res.status >= 300) {
            throw new Error('This request has failed ' + res.status);
          } else {
            return [{ status: res.status }]
          }
        }
      }).catch((error: Response | any) => {
        let errMsg: string;
        if (error.status == 401) {
          localStorage.removeItem('auth_token');
          this.router.navigate(['login']);
          errMsg = 'unauthorisiert';
        }
        return Observable.throw(errMsg);
      });
  }

  public DeleteRequest(url:string, contentType='application/json') {
    this.headers.append('Content-Type', contentType);
    this.requestoptions = new RequestOptions({
      method: RequestMethod.Delete,
      url: url,
      headers: this.headers
    });
    return this._http.request(new Request(this.requestoptions))
      .map((res: Response) => {
        if (res) {
          if (res.status < 200 || res.status >= 300) {
            throw new Error('This request has failed ' + res.status);
          } else {
            return [{ status: res.status }]
          }
        }
      }).catch((error: Response | any) => {
        let errMsg: string;
        if (error.status == 401) {
          localStorage.removeItem('auth_token');
          this.router.navigate(['login']);
          errMsg = 'unauthorisiert';
        }
        return Observable.throw(errMsg);
      });
  }
}
