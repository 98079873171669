import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CompetenceService } from '../services/competence.service';
import { Competence } from './competence.model';
import { CompetencedetailComponent } from './competencedetail'
import { CompetencelistComponent } from './competencelist/competencelist.component';

@Component({
  moduleId: module.id,
  selector: 'app-competence',
  templateUrl: 'competence.component.html',
  providers: [
		CompetenceService
  ],
  styleUrls: ['competence.component.scss'],
})

export class CompetenceComponent{

  @ViewChild(CompetencelistComponent) _competenceList: CompetencelistComponent;

  competence: Competence[];

	public listOfCompetence:Competence;

    constructor(
		private _competenceService: CompetenceService,
		private _router: Router) {

    }

	gotoDetail(competence:Competence) {
		let link = ['/competence/' + competence.expertiseID];
		this._router.navigate(link);
	}

	createCompetence() {
		let link = ['/competence/new'];
		this._router.navigate(link);
	}


}
