import { Component, OnInit, EventEmitter, Output} from '@angular/core';
import { ProductService } from '../services/product.service';
import { Product } from './product.model';

@Component({
  moduleId: module.id,
  selector: 'app-product',
  templateUrl: 'product.component.html',
  providers: [ ProductService ],
  styleUrls: ['product.component.scss'],
  inputs: ['selectedProducts', 'refreshable', 'single', 'edit'],
})

export class ProductComponent implements OnInit{

  public selectedProducts: Product[];
  public refreshable: boolean = true;
  public single: boolean;
  public edit: boolean = true;
  @Output() chosenProduct = new EventEmitter();

  ngOnInit() {
    this.returnProductList();

  }

  listOfProducts: Array<Product>;

  constructor(
  private _productService: ProductService) {

  }

  public getSelectedProducts(){
    return this.selectedProducts;
  }

  private returnProduct(product: Product) {
    this.chosenProduct.next(product);
  }

  private refreshProducts(){
    this.refreshProductList();
  }

  private toggleProduct(product: Product, event){
    if(this.single){ //Zuordnung von Highlights
      for (var key in this.selectedProducts) {
        if (this.selectedProducts.hasOwnProperty(key)) {
          this.selectedProducts[key] = undefined;
        }
      }
      if(event.currentTarget.checked == true){
        this.selectedProducts["linkTitle"] = product["produktgruppe"];
        this.selectedProducts["linkShortDescription"] = product["produktkurzbeschreibung"];
        this.selectedProducts["linkTitleImage"] = product["produktbild"];
        this.selectedProducts["linkCollection"] = "product";
        this.selectedProducts["linkID"] = product["gr_sys_pk"];
      }else if(event.currentTarget.checked == false){
        this.selectedProducts = [];
      }
    }else if(!this.single){
      if(event.currentTarget.checked == true){
        this.selectedProducts.push(product);
      }else if(event.currentTarget.checked == false){
        var i;
        for (i = 0; i < this.selectedProducts.length; i++) {
            if (this.selectedProducts[i]["wmv_id"] === product["wmv_id"]) {
                this.selectedProducts.splice(i , 1);
            }
        }

      }
    }
    console.log(this.selectedProducts);

  }

  private inAssignements(product: Product){
    var i;
    var checked = false;
    if(this.single){ //Zuordnung von Highlights
      if(this.selectedProducts["linkID"] === product["gr_sys_pk"]){
        checked = true;
      }
    }else if(!this.single){
      for (i = 0; i < this.selectedProducts.length; i++) {
          if (this.selectedProducts[i]["wmv_id"] === product["wmv_id"]) {
              checked = true;
          }
          if(checked) break;
      }
    }
    return checked;
  }

  returnProductList(){
      this._productService.returnProductList().subscribe(
          data => {
            console.log("HTTP-Status: ", data["0"].status);
            this.listOfProducts = data["0"].json ;
          },
          err => console.error(err),
          () => console.log('done loading products')
      );
  }

  refreshProductList(){
    this._productService.postProductList().subscribe(
      data => {
         console.log("Data returned from WebService: ", data["0"].json)
        // console.log("refreshed");
        this.returnProductList();
      },
        err => console.error(err),
        () => console.log("Updated Products")
      );
  }


}
