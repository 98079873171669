import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { RestService } from './rest.service';
import myGlobals = require('./../globals');
import { Router } from '@angular/router';


@Injectable()
export class SystemService extends RestService {

    constructor(
      http: Http,
      router: Router
    ) {
      super(http, router);
    }

    returnSystemList() {
        return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'systems?client='+myGlobals.apiClient);

    }

    postSystemList(){
      return this.PostRequest(myGlobals.apiPath+myGlobals.apiVersion+'systems/updateFromCMS?client='+myGlobals.apiClient, {});
    }

}
