import { Component, OnInit } from '@angular/core';
import { FtpStatus, UploadStatus } from './export.model';
import { ExportService } from '../services/export.service';
import { FolioDetailService } from '../services/foliodetail.service';
import myGlobals = require('./../globals');

@Component({
  moduleId: module.id,
  selector: 'app-export',
  templateUrl: 'export.component.html',
  styleUrls: ['export.component.scss'],
  providers: [ ExportService, FolioDetailService ]
})

export class ExportComponent implements OnInit {

  public _myGlobals = myGlobals;
  public listOfFtpStatuses: Array<FtpStatus>;
  public listOfUploadStatuses: Array<UploadStatus>;
  public listOfFtpStatusesLength: any;
  public alerts:Array<Object> = [];
  public _myGlobalsPDFstatusesLength: any;
  public chosenFilter: any = '';

  constructor(
    private _exportService: ExportService,
    private _folioDetailService: FolioDetailService
  ){
    this.listOfFtpStatuses = new Array<FtpStatus>();
    this.listOfUploadStatuses = new Array<UploadStatus>();
    this._myGlobalsPDFstatusesLength = Object.keys(this._myGlobals.PDFstatuses);
  }

  ngOnInit() {
      this.returnFTPLog();
      this.returnUploadstatus("UPLOADED");
  }

  public addSuccessAlert():void {
    this.alerts.push({msg: 'Erfolgreich ausgeführt', type: 'success', closable: true, dismissOnTimeout: 3000});
  }

  public addErrorAlert():void {
    this.alerts.push({msg: 'Es ist ein Fehler aufgetreten', type: 'danger', closable: true, dismissOnTimeout: 3000});
  }

  public addNoticeAlert():void {
    this.alerts.push({msg: 'Die App Inhalte werden exportiert', type: 'warning', closable: true, dismissOnTimeout: false});
  }

  public publishAll(){
    console.log("Try to publish all!");
    for(let i = 0; i < this.listOfUploadStatuses.length; i++){
      this._folioDetailService.postFolioStatus(this.listOfUploadStatuses[i], "publish").subscribe(
        data => {
           console.log("Data returned from WebService: ", data["0"].json);
        },
          err => console.error(err),
          () => {
            console.log("Exportet Successfully");
            this.addSuccessAlert();
            this.returnUploadstatus("UPLOADED");
          }
        );
    }
  }

  public exportAppContent(){
    this.addNoticeAlert();
    this._exportService.postAppContent().subscribe(
      data => {
       var result:Object = data["0"].json;
       console.log("Data returned from WebService: ", data["0"].json)
      },
          err => {
            console.error(err);
            this.addErrorAlert();
          },
          () => {
            console.log("Exportet Successfully");
            this.alerts.splice(0, this.alerts.length);
            this.addSuccessAlert();
          }
      );
  }

  returnFTPLog() {
      this._exportService.returnFTPLog().subscribe(
          data => {
            console.log("HTTP-Status: ", data["0"].status);
            this.listOfFtpStatuses = data["0"].json.versions;
            this.listOfFtpStatusesLength = Object.keys(this.listOfFtpStatuses);

            console.log(this.listOfFtpStatuses);
          },
          err => console.error(err),
          () => console.log('done loading FtpStatus')
      );
  }

  returnUploadstatus(status){
    this._exportService.returnUploadstatus(status).subscribe(
        data => {
          console.log("HTTP-Status: ", data["0"].status);
          this.listOfUploadStatuses = data["0"].json;
        },
        err => console.error(err),
        () => console.log('done loading Uploadstatus')
    );
  }

}
