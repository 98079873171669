import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import { UserService } from '../services/user.service';
import { User } from './users.model';

@Component({
  moduleId: module.id,
  selector: 'app-settings',
  providers: [
    UsersService
  ],
  templateUrl: 'settings.component.html',
  styleUrls: ['settings.component.css']
})
export class SettingsComponent implements OnInit {

  listOfUsers: User[];
  newUser: User = new User();
  activeUser: number;
  newPW: string;
  confirmPW: string;
  newUserpwConfirm: string;
  isAdmin: boolean;
  KIFVSuser: string;

  constructor(
    private _usersService: UsersService,
    private _userService: UserService
  ) {

  }

  ngOnInit() {
    this.isAdmin = this._userService.returnAdmin();
    this.KIFVSuser = this._userService.returnUser();
    this.returnUsersList();
  }

  private returnUsersList() {
    console.log("get Users");
    this._usersService.returnUsersList().subscribe(
      data => {
        console.log("HTTP-Status: ", data["0"].status);
        this.listOfUsers = data["0"].json;
        console.log(data["0"].json)
      },
      err => console.error(err),
      () => console.log('done loading users')
    );
  }

  private addUser(){
    this._usersService.addUser(this.newUser).subscribe(
      data => {
        console.log("status"+data);
        this.newUser = new User();
        this.newUserpwConfirm = undefined;
        this.returnUsersList();
      },
      err => {
        console.error(err);
      },
      () => {
        console.log("User added");
      }
    );
  }

  private deleteUser(user: User){
    console.log(user);
    this._usersService.deleteUser(user).subscribe(
      data => {
        console.log("status"+data);
        this.returnUsersList();
      },
      err => {
        console.error(err);
      },
      () => {
        console.log("User deleted");
      }
    );
  }

  private updateUser(user: User){
    this._usersService.updateUser(user, this.newPW).subscribe(
      data => {
        console.log("status"+data);
        this.newPW = this.confirmPW = undefined;
        this.returnUsersList();
      },
      err => {
        console.error(err);
      },
      () => {
        console.log("User updated");
      }
    );
  }

}
