import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CompetenceService } from "../../services/competence.service";
import { AppcontentService } from '../../services/appcontent.service';
import { CompetencedetailService } from "../../services/competencedetail.service";
import { Competence, Link, Document } from './../competence.model';
import { Http, Response, Headers, Request, RequestMethod, RequestOptions } from '@angular/http';
import myGlobals = require('./../../globals');

@Component({
  moduleId: module.id,
  selector: 'app-competencedetail',
  providers: [CompetencedetailService, CompetenceService, AppcontentService],
  templateUrl: 'competencedetail.component.html',
  styleUrls: ['competencedetail.component.scss'],
})

export class CompetencedetailComponent implements OnInit {

  public competence: Competence;
  public _allCompetences: Array<Competence>;

  public alerts:Array<Object> = [];
  public uploadURL : string;
  public _myGlobals;
  public headers:Headers;
  public file: File[];
  private sub: any;
  public allTiers: Array<any> = new Array();

  // @ViewChild(FoliolistComponent) _folioList: FoliolistComponent;
  ngOnInit() {
    console.debug("CompetencedetailComponent instantiated");
    this.getAllFolioNames();
    this.returnTiers();

    this.sub = this.route.params.subscribe(params => {
     let  id = params['id'];
     if(id != "new"){
       this.returnCompetenceDetail(id);
     }
    })

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  constructor(
    private _competenceDetailService: CompetencedetailService,
    private _competenceService: CompetenceService,
    private _appcontentService: AppcontentService,
    private _router: Router,
    private route: ActivatedRoute ) {
      this.competence = new Competence();
      this.competence.links = [{title: null, targetUrl: null}, {title: null, targetUrl: null}, {title: null, targetUrl: null}];
      this.competence.documents = [];
      this.competence.filters = [];
      this._myGlobals = myGlobals;
    }

  private getAllFolioNames(){
    console.log("getAllFolioNames called");
    this._competenceService.returnCompetenceList().subscribe(
      data=>{ console.log("HTTP-Status: ", data["0"].status); this._allCompetences =  data["0"].json},
      err => console.error(err),
      () => console.log ("Ok got the folio: ", this._allCompetences)
    );
  }

  public replacePSFKS(selectedPSFKS){
    this.competence.filters[0].tier1 = selectedPSFKS;
  }

  //Picture Handling
  onUpload(event) {
    let response = JSON.parse(event.xhr.responseText);
    this.competence.expertiseImage = response.assetPath+"?random="+Math.random();
  }

  onBeforeSend(event) {
    console.log(event);
    this.headers = new Headers();
    let token = localStorage.getItem('auth_token');
    event.xhr.setRequestHeader("Authorization", "Bearer "+token);
  }

  returnTiers() {
    this._appcontentService.returnTiers().subscribe(
      data => {
        console.log("HTTP-Status: ", data["0"].status);
        this.allTiers = data["0"].json
        console.log(this.allTiers)
      },
      err => console.error(err),
      () => console.log('done loading Tiers')
    );
  }

  returnCompetenceDetail(id:string) {
    this._competenceDetailService.returnCompetenceDetail(id).subscribe(
         data => {
          console.log("HTTP-Status ", data["0"].status);
          this.competence = data["0"].json;
          console.log(this.competence)
          for(let i = 0; i < this.competence.links.length; i++){
            if(this.competence.links[i]["title"] == "none" || this.competence.links[i]["targetUrl"] == "none"){
              this.competence.links[i]["title"] = null;
              this.competence.links[i]["targetUrl"] = null;
            }
          }
        },
        err => console.error(err),
        () => console.log('done loading competences')
      );
    }

  postCompetenceDetail() {
    console.log(this.competence);
    this._competenceDetailService.postCompetenceDetail(this.competence).subscribe(
    data => {
       console.log("Data returned from WebService: ", data)
      },
        err => {
          console.error(err);
          this.addErrorAlert();
        },
        () => {
          console.log("Competenz erstellt");
          this.addSuccessAlert();
          let link = ['/competence/' + this.competence.expertiseID];
          this._router.navigate(link);
        }
      );
  }

  putCompetenceDetail() {
    this._competenceDetailService.putCompetenceDetail(this.competence).subscribe(
    data => {
       var result:Object = data["0"].json;
       console.log("Data returned from WebService: ", data["0"].json)
      },
        err => {
          console.error(err);
          this.addErrorAlert();
        },
        () => {
          console.log("Competenz gespeichert");
          this.addSuccessAlert();
        }
      );
  }

  deleteCompetence(competence: Competence){
    this._competenceService.deleteCompetence(competence).subscribe(
      data => {
        var result:Object = data["0"].json;
        console.log("Data returned from WebService: ", data["0"].json)
      },
      err => console.error(err),
      () => console.log("Deleted Competence")
    );
  }

  public addSuccessAlert():void {
    this.alerts.push({msg: 'Erfolgreich gespeichert', type: 'success', closable: true});
  }

  public addErrorAlert():void {
    this.alerts.push({msg: 'Beim Speichern ist eine Fehler aufgetreteten', type: 'danger', closable: true});
  }

  private onCancel() {
    let link = ['/competence'];
    this._router.navigate(link);
  }

  private onDelete() {
    this.deleteCompetence(this.competence)
    this.onCancel()
  }

  private onSave(){
    let id = this.route.snapshot.params["id"];
    for(let i = 0; i < this.competence.documents.length; i++){
      if(this.competence.documents[i]["articles"] != undefined){
        delete this.competence.documents[i]["articles"];
      }
    }
    for(let i = 0; i < this.competence.links.length; i++){
      if(this.competence.links[i]["title"] == null || this.competence.links[i]["targetUrl"] == null || this.competence.links[i]["title"] == "" || this.competence.links[i]["targetUrl"] == ""){
        this.competence.links[i]["title"] = "none";
        this.competence.links[i]["targetUrl"] = "none";
      }
    }
    if (this.competence.psfks) {
      delete this.competence.psfks
    }
    console.log(this.competence);
    if(id == "new"){
      this.postCompetenceDetail();
    }else{
      this.putCompetenceDetail();
    }
  }

  private removeAssignment(index:number) {
    this.competence.documents.splice(index, 1);
  }
}
