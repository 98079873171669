import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SystemService } from '../services/system.service';
import { System } from './system.model';

@Component({
  moduleId: module.id,
  selector: 'app-system',
  templateUrl: 'system.component.html',
  providers: [ SystemService ],
  // directives: [ DataTable, Column],
  styleUrls: ['system.component.scss'],
  inputs: ['selectedSystems', 'refreshable', 'single', 'edit']
})

export class SystemComponent implements OnInit {

  public selectedSystems: System[];
  public refreshable: boolean = true;
  public single: boolean;
  public edit: boolean = true;
  @Output() chosenSystem = new EventEmitter();

  ngOnInit() {
    this.returnSystemList();
  }

  listOfSystems: Array<System>;

  constructor(
  private _systemService: SystemService) {
  }

  public getSelectedSystems(){
    return this.selectedSystems;
  }

  private refreshSystems(){
    this.refreshProductList();
  }

  private returnSystem(system: System) {
    this.chosenSystem.next(system);
  }

  private toggleSystems(system: System, event){
    console.log(system);
    if(this.single){ //Zuordnung von Highlights
      for (var key in this.selectedSystems) {
        if (this.selectedSystems.hasOwnProperty(key)) {
          this.selectedSystems[key] = undefined;
        }
      }
      if(event.currentTarget.checked == true){
        this.selectedSystems["linkTitle"] = system["dokumententitel"];
        this.selectedSystems["linkShortDescription"] = system["teaser_text"];
        this.selectedSystems["linkTitleImage"] = system["systemgruppenbild"];
        this.selectedSystems["linkCollection"] = "system";
        this.selectedSystems["linkID"] = system["gr_sys_pk"];
      }else if(event.currentTarget.checked == false){
        this.selectedSystems = [];
      }
    }else if(!this.single){
      if(event.currentTarget.checked == true){
        this.selectedSystems.push(system);
      }else if(event.currentTarget.checked == false){
        var i;
        for (i = 0; i < this.selectedSystems.length; i++) {
            if (this.selectedSystems[i]["wmv_id"] === system["wmv_id"]) {
                this.selectedSystems.splice(i , 1);
            }
        }

      }
    }
  }

  private inAssignements(system: System){
    var i;
    var checked = false;
    if(this.single){ //Zuordnung von Highlights
      if(this.selectedSystems["linkID"] === system["gr_sys_pk"]){
        checked = true;
      }
    }else if(!this.single){
      for (i = 0; i < this.selectedSystems.length; i++) {
        if (this.selectedSystems[i]["wmv_id"] === system["wmv_id"]) {
          checked = true;
        }
        if(checked) break;
      }
    }
    return checked;
  }

  returnSystemList() {
      this._systemService.returnSystemList().subscribe(
          data => {
            console.log("HTTP-Status: ", data["0"].status);
            this.listOfSystems = data["0"].json;
          },
          err => console.error(err),
          () => console.log('done loading systems')
      );
  }


  refreshProductList(){
    this._systemService.postSystemList().subscribe(
      data => {
         console.log("Data returned from WebService: ", data["0"].json)
        // console.log("refreshed");
        this.returnSystemList();
      },
        err => console.error(err),
        () => console.log("Updated Products")
      );
  }

}
