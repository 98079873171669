import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { RestService } from './rest.service';
import { Document } from './../document/document.model';
import myGlobals = require('./../globals');
import { Router } from '@angular/router';

@Injectable()
export class WmvService extends RestService {

  constructor(
    http: Http,
    router: Router
  ) {
    super(http, router);
  }

  returnWmvList() {
    return this.GetRequest(myGlobals.apiPath + myGlobals.apiVersion + 'documents?client=' + myGlobals.apiClient);
  }

  returnUnsedDocuments() {
    return this.GetRequest(myGlobals.apiPath + myGlobals.apiVersion + 'documents/unused?client=' + myGlobals.apiClient);
  }

  returnDocumentDetail(wmv_id:string, type:string='') {
    return this.GetRequest(myGlobals.apiPath + myGlobals.apiVersion + 'documents/' + wmv_id + '/' + type.toLowerCase() + '?client=' + myGlobals.apiClient);
  }

  returnProductsByWmvId(wmv_id:string) {
    return this.GetRequest(myGlobals.apiPath + myGlobals.apiVersion + 'products/' + wmv_id + '?client=' + myGlobals.apiClient);
  }

  returnSystemsByWmvId(wmv_id:string) {
    return this.GetRequest(myGlobals.apiPath + myGlobals.apiVersion + 'systems/' + wmv_id + '?client=' + myGlobals.apiClient);
  }

  postDocumentDetail(document:Document) {
    return this.PostRequest(myGlobals.apiPath + myGlobals.apiVersion + 'documents?client=' + myGlobals.apiClient, document);
  }

  putDocumentDetail(document:Document) {
    return this.PutRequest(myGlobals.apiPath + myGlobals.apiVersion + 'documents/' + document['wmv_id'] + '/' + document['type'] + '?client=' + myGlobals.apiClient, document);
  }

  deleteDocument(document:Document) {
    return this.DeleteRequest(myGlobals.apiPath + myGlobals.apiVersion + 'documents/' + document['wmv_id'] + '/' + document['type'] + '?client=' + myGlobals.apiClient);
  }

  postUpdateFromWordpress() {
    return this.PostRequest(myGlobals.apiPath + myGlobals.apiVersion + 'documents/updateFromWP?client=' + myGlobals.apiClient, {});
  }

  postUpdateFromCMS() {
    return this.PostRequest(myGlobals.apiPath + myGlobals.apiVersion + 'documents/updateFromCMS?client=' + myGlobals.apiClient, {});
  }

  postDownloadPDFs() {
    return this.PostRequest(myGlobals.apiPath + myGlobals.apiVersion + 'documents/downloadPDFs?client=' + myGlobals.apiClient, {});
  }

  postDownloadWordpress() {
    return this.PostRequest(myGlobals.apiPath + myGlobals.apiVersion + 'documents/downloadFromWP?client=' + myGlobals.apiClient, {});
  }

  postAnalyzePDFs() {
    return this.PostRequest(myGlobals.apiPath + myGlobals.apiVersion + 'documents/analyzePDFs?client=' + myGlobals.apiClient, {});
  }
}
