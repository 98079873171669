export default class Helpers {
  
  static renameTiers(tiers: Array<any>) {
    return tiers.map((tier) => {
      return {
        label: tier.name,
        data: tier.UUID ? tier.UUID: undefined,
        children: (tier.tiers && tier.tiers.length > 0) ? this.renameTiers(tier.tiers) : []
      }
    })
  }

  static getNameForUUID(psfks: string, tiers: Array<any>) {
    const result = this.flatten(tiers).filter((tier) => tier.data === psfks)
    return (result.length > 0) ? result[0].label : psfks
  }

  static flatten(array: Array<any>) {
    var result = [];
    array.forEach((a) => {
        result.push(a);
        if (Array.isArray(a.children) && a.children.length > 0) {
            result = result.concat(this.flatten(a.children));
        }
    });
    return result;
  }

}