import { Component, Output, EventEmitter } from '@angular/core';
import { CompetenceService } from '../../services/competence.service';
import { Competence } from './../competence.model';
import {DataTable, Column, Button} from 'primeng/primeng';

@Component({
  moduleId: module.id,
  selector: 'app-competencelist',
  templateUrl: 'competencelist.component.html',
  providers: [
		CompetenceService
	],
  styleUrls: ['competencelist.component.scss'],
  inputs: ['edit', 'selectedCompetences', 'single'],
})
export class CompetencelistComponent{

  public edit: boolean = false;
  public selectedCompetences: Competence[];
  public single: boolean;
  @Output() chosenCompetence = new EventEmitter();

  listOfCompetence: Competence[];

  constructor(
    private _competenceService: CompetenceService) {
	     this.returnCompetenceList();
  }

  private returnCompetence(competence: Competence) {
    this.chosenCompetence.next(competence);
  }

  public getSelectedCompetence(){
    return this.selectedCompetences;
  }

  private refreshStatus() {
    this.returnCompetenceList();
    console.log('refreshing');
  }


  private toggleCompetence(competence: Competence, event){
    console.log(competence);
    if(this.single){ //Zuordnung von Highlights
      for (var key in this.selectedCompetences) {
        if (this.selectedCompetences.hasOwnProperty(key)) {
          this.selectedCompetences[key] = undefined;
        }
      }
      if(event.currentTarget.checked == true){
        this.selectedCompetences["linkTitle"] = competence["expertiseName"];
        this.selectedCompetences["linkShortDescription"] = competence["expertiseShortDescription"];
        this.selectedCompetences["linkTitleImage"] = competence["expertiseImage"];
        this.selectedCompetences["linkCollection"] = "expertise";
        this.selectedCompetences["linkID"] = competence["expertiseID"];
      }else if(event.currentTarget.checked == false){
        this.selectedCompetences = [];
      }
    }else if(!this.single){
      if(event.currentTarget.checked == true){
        this.selectedCompetences.push(competence);
      }else if(event.currentTarget.checked == false){
        var i;
        for (i = 0; i < this.selectedCompetences.length; i++) {
            if (this.selectedCompetences[i]["expertiseID"] === competence["expertiseID"]) {
                this.selectedCompetences.splice(i , 1);
            }
        }

      }
    }
  }

  private inAssignements(competence: Competence){
    var i;
    var checked = false;
    if(this.single){ //Zuordnung von Highlights
      if(this.selectedCompetences["linkID"] === competence["expertiseID"]){
        checked = true;
      }
    }else if(!this.single){
      for (i = 0; i < this.selectedCompetences.length; i++) {
          if (this.selectedCompetences[i]["expertiseID"] === competence["expertiseID"]) {
              checked = true;
          }
          if(checked) break;
      }
    }
    return checked;
  }

  returnCompetenceList() {
    this._competenceService.returnCompetenceList().subscribe(
      data => {
        console.log("HTTP-Status: ", data["0"].status);
        this.listOfCompetence = data["0"].json;
        for (let i = 0; i < this.listOfCompetence.length; i++) {
          if (!this.listOfCompetence[i].order) {
            this.listOfCompetence[i].order = 999;
          }
        }
      },
      err => console.error(err),
      () => console.log(this.listOfCompetence)
    );
  }


}
