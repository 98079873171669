import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { RestService } from './rest.service';
import myGlobals = require('./../globals');
import { Router } from '@angular/router';

@Injectable()
export class FolioService extends RestService {

  constructor(
    http: Http,
    router: Router
  ) {
    super(http, router);
  }

  returnFolioList() {
    return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'folios?client='+myGlobals.apiClient);
  }

  deleteFolio(folio:Object) {
    return this.DeleteRequest(myGlobals.apiPath+myGlobals.apiVersion+'folios/'+folio["folioName"]+'?client='+myGlobals.apiClient);
  }

}
