import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AutocompleteEntry, PredefinedEntry, AppTexts, Hightlight, ButtonText, Tier } from './appcontent.model';
import { AppcontentService } from '../services/appcontent.service';
import myGlobals = require('./../globals');
import Helpers from '../helpers/helpers';
import { Headers } from '@angular/http';
import { Competence } from '../competence/competence.model';

@Component({
  moduleId: module.id,
  selector: 'app-appcontent',
  templateUrl: 'appcontent.component.html',
  styleUrls: ['appcontent.component.scss'],
  providers: [ AppcontentService ]
})
export class AppcontentComponent implements OnInit {

  public listOfAutocompleteEntries: any = new Array();
  public listOfPredefinedEntries: Array<PredefinedEntry>;
  public listOfButtonTexts: Array<ButtonText>;
  public _myGlobals;
  public headers: Headers;

  public appContents: any;
  public listOfHighlights: Array<Hightlight>;
  public newRow: PredefinedEntry;
  public newButton: ButtonText;
  public activeButton: ButtonText;
  public activeEntry: number;
  public activeHighlight: Object;
  public alerts:Array<Object> = [];
  public categoryLabels: Object;
  public newFilter: String;
  public allTiers: Array<Tier>;
  public psfkImages: any = new Array();
  public activePsfkFolio: Object;

  listOfCompetence: Competence[];

  constructor(
    private _appcontentService: AppcontentService,
    private _router: Router
  ) {
    this.appContents = new Object();
    this.appContents.entries = [];
    this.listOfPredefinedEntries = new Array<PredefinedEntry>();
    this.newRow = new PredefinedEntry();
    this.allTiers = new Array();
    this.newButton = new ButtonText();
    this.activeButton = new ButtonText();
    this.activeHighlight = new Object();
    this.activePsfkFolio = new Object();
    this.categoryLabels = {"expertise": "Kompetenz", "product": "Produkt", "system": "System"};
    this._myGlobals = myGlobals;
    this.returnCompetenceList();
  }

  ngOnInit() {
    this.returnAutocompleteEntries();
    this.returnPredefinedEntries();
    this.returnAppcontents();
    this.returnButtonTexts();
    this.returnTiers();
  }

  // PredefinedSearchEntries
  public addSuccessAlert():void {
    this.alerts.push({msg: 'Erfolgreich gespeichert', type: 'success', closable: true});
  }

  public addErrorAlert():void {
    this.alerts.push({msg: 'Beim Speichern ist eine Fehler aufgetreteten', type: 'danger', closable: true});
  }

  public addHightlight() {
    this.appContents.entries.push({});
  }

  public deleteHighlight(index) {
    this.appContents.entries.splice(index, 1);
  }

  public addEntry() {
    const newSearchEntry = {
      name: this.newRow.name,
      psfks: []
    };
    this.listOfPredefinedEntries.push(newSearchEntry);
    this.postPredefinedEntry(newSearchEntry);
    this.newRow = new PredefinedEntry();
    this.newRow.psfks = [];
  }

  private deleteSearchEntry(predefinedEntry, index) {
    this.deletePredefinedEntry(predefinedEntry);
    this.listOfPredefinedEntries.splice(index, 1);
  }

  private updateSearchEntry(predefinedEntry){
    this.putPredefinedEntry(predefinedEntry);
  }

  public addPSFKS(selectedPSFKS, activeEntry){
    this.listOfPredefinedEntries[activeEntry].psfks = [...this.listOfPredefinedEntries[activeEntry].psfks, selectedPSFKS.data].filter((x, i, a) => a.indexOf(x) == i)
  }

  public deletePSFKS(activeEntry, index){
    this.listOfPredefinedEntries[activeEntry].psfks.splice(index, 1);
  }

  // Buttontexts
  private addButtonText(){
    this.listOfButtonTexts.push(this.newButton);
    this.postButtonText(this.newButton);
    this.newButton = new ButtonText();
  }

  private updateButtonText(button){
    this.putButtonText(button);
  }

  private editCompetence(expertiseID) {
    let link = ['/competence/'+expertiseID];
    this._router.navigate(link);
  }

  returnAutocompleteEntries() {
      this._appcontentService.returnAutocompleteEntriesList().subscribe(
          data => {
            console.log("HTTP-Status: ", data["0"].status);
            for(var i = 0; i < data["0"].json.entries.length; i++){
              this.listOfAutocompleteEntries.push({entry: data["0"].json.entries[i]});
            }
          },
          err => console.error(err),
          () => console.log('done loading AutocompleteEntries')
      );
  }

  returnCompetenceList() {
    this._appcontentService.returnCompetenceList().subscribe(
      data => {
        console.log(" --> HTTP-Status: ", data["0"].status);
        this.listOfCompetence = data["0"].json;
        for (let i = 0; i < this.listOfCompetence.length; i++) {
          if (!this.listOfCompetence[i].order) {
            this.listOfCompetence[i].order = 999;
          }
        }
      },
      err => console.error(err),
      () => console.log(this.listOfCompetence)
    );
  }

  returnPredefinedEntries() {
      this._appcontentService.returnPredefinedEntriesList().subscribe(
          data => {
            console.log("HTTP-Status: ", data["0"].status);
              this.listOfPredefinedEntries = data["0"].json ;
          },
          err => console.error(err),
          () => console.log('done loading PredefinedEntries')
      );
  }

  postPredefinedEntry(predefinedEntry: PredefinedEntry){
    this._appcontentService.postPredefinedEntry(predefinedEntry).subscribe(
      data => {
       var result:Object = data["0"].json;
       console.log("Data returned from WebService: ", data["0"].json)
      },
          err => {
            console.error(err);
            this.addErrorAlert();
          },
          () => {
            console.log("Saved PredefinedEntry");
            this.addSuccessAlert();
            this.returnPredefinedEntries();
          }
      );
  }

  putPredefinedEntry(predefinedEntry: PredefinedEntry){
    this._appcontentService.putPredefinedEntry(predefinedEntry).subscribe(
      data => {
       var result:Object = data["0"].json;
       console.log("Data returned from WebService: ", data["0"].json)
      },
          err => {
            console.error(err);
            this.addErrorAlert();
          },
          () => {
            console.log("Saved PredefinedEntry");
            this.addSuccessAlert();
          }
      );
  }

  deletePredefinedEntry(predefinedEntry: PredefinedEntry){
    this._appcontentService.deletePredefinedEntry(predefinedEntry).subscribe(
      data => {
       var result:Object = data["0"].json;
       console.log("Data returned from WebService: ", data["0"].json)
      },
          err => console.error(err),
          () => console.log("Deleted PredefinedEntry")
      );
  }

  returnButtonTexts() {
      this._appcontentService.returnButtonTexts().subscribe(
          data => {
            console.log("HTTP-Status: ", data["0"].status);
              this.listOfButtonTexts = data["0"].json ;
          },
          err => console.error(err),
          () => console.log('done loading Buttontexts')
      );
  }

  postAppcontents() {
    this.preparePsfkImages()
    this._appcontentService.postAppcontents(this.appContents).subscribe(
      data => {
       var result:Object = data["0"].json;
       console.log("Data returned from WebService: ", data["0"].json)
      },
        err => {
          console.error(err);
          this.addErrorAlert();
        },
        () => {
          console.log("Saved Appcontents");
          this.addSuccessAlert();
        }
      );
  }

  // Use cover folio of psfk images only for modal in ui
  // If a cover folio was defined, set the cover url for app psfk images
  preparePsfkImages() {
    // Check if there are psfk images
    if (this.appContents.psfkImages && this.appContents.psfkImages.length > 0) {
      for (const tier of this.appContents.psfkImages) {
        // Ignore all expertise images
        if (tier !== 'expertise') {
          for (const secondTier of tier.tiers) {
            // Check if a cover folio was defined
            if (secondTier.coverFolio && secondTier.coverFolio.folioTitleImage) {
              // Set the cover url
              secondTier.coverUrl = secondTier.coverFolio.folioTitleImage
            }
          }
        }
      }
    }
  }

  postButtonText(button: ButtonText) {
    this._appcontentService.postButtonText(button).subscribe(
      data => {
       var result:Object = data["0"].json;
       console.log("Data returned from WebService: ", data["0"].json)
      },
        err => {
          console.error(err);
          this.addErrorAlert();
        },
        () => {
          console.log("Saved Buttontext");
          this.addSuccessAlert();
        }
      );
  }

  putButtonText(button: ButtonText) {
    this._appcontentService.putButtonText(button).subscribe(
      data => {
       var result:Object = data["0"].json;
       console.log("Data returned from WebService: ", data["0"].json)
      },
        err => {
          console.error(err);
          this.addErrorAlert();
        },
        () => {
          console.log("Updated Buttontext");
          this.addSuccessAlert();
        }
      );
  }

  returnAppcontents() {
      this._appcontentService.returnAppcontents().subscribe(
          data => {
            console.log("HTTP-Status: ", data["0"].status, data["0"].json);
              this.appContents = data["0"].json ;
          },
          err => console.error(err),
          () => console.log('done loading Appcontents')
      );
  }


  returnTiers() {
    this._appcontentService.returnTiers().subscribe(
      data => {
        console.log("HTTP-Status: ", data["0"].status);
        console.log(data["0"].json)
        this.allTiers = Helpers.renameTiers(data["0"].json);
        // Initializes App Contents for PSFK Images, if there are no created yet.
        if (this.appContents && !this.appContents.psfkImages) {
          this.initPsfkImagesArray()
        }
      },
      err => console.error(err),
      () => console.log('done loading Tiers')
    );
  }

  initPsfkImagesArray() {
    const psfkImages = []
    this.allTiers.forEach((element, index) => {
      psfkImages.push({
        label: element.label,
        tiers: []
      })
      element.children.forEach(child => {
        psfkImages[index].tiers.push({
          label: child.label,
          coverFolio: new Object(),
          coverUrl: null
        })
      })
    })
    this.appContents.psfkImages = psfkImages;
  }

  onBeforeSend(event) {
    this.headers = new Headers();
    const token = localStorage.getItem('auth_token');
    event.xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  }

  onUpload(event, tier, secondTier, index, subIndex) {
    const response = JSON.parse(event.xhr.responseText);
    this.appContents.psfkImages[index].tiers[subIndex].coverUrl = response.assetPath + '?random=' + Math.random();
    this.appContents.psfkImages[index].tiers[subIndex].coverFolio = new Object();
  }

  getNameForUUID(psfks, tiers) {
    return Helpers.getNameForUUID(psfks, tiers)
  }
}
