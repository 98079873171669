export class Folio {
  folioProductID: string;
  folioType: string;
  folioLanguage: string;
  folioName: string;
  folioDescription: string;
  folioDocumentType: string;
  folioFormats: string;
  documentDate: string;
  printNumber: string;
  STATUS: string;
  version: number;
  messages: Array<Message>;
  indexTitleImage: string;
  folioCoverImage: string;
  isArticleInFolio: string;
  folioIsHighlight: string;
  WMVID: number;
  articles: Array<FolioArticle>;
}

export class FolioArticle {
  articleName: string;
  articleTitle: string;
  articleNumber: number;
  articleDownloadLink: string;
  date: string;
  letzte_upload_aenderung: string;
  STATUS: string;
  WMVID: string;
  printNumber: number;
  articlePageFrom: number;
  articlePageTo: number;
  pageCount: number;
  sections: Array<FolioSection>;
  buttons: Array<Button>;
  invalid: boolean = false;
  hidden: boolean = false;
}

export class FolioSection {
  sectionTitle: string;
  sectionNumber: number;
  WMVID: string;
  downloadLink: string;
  printNumber: string;
  date: string;
  letzte_upload_aenderung: string;
  pageFrom: number;
  pageTo: number;
  pageCount: number;
}

export class Button {
  page: number;
  buttonType: string;
  texts: Array<ButtonText>;
}

export class ButtonText {
  title: string;
  htmlContent: string;
  url: string;
}

export class Message {
  level: string;
  msg: string;
  timestamp: string;
}
