import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import {RestService} from './rest.service';
import myGlobals = require('./../globals');
import { Router } from '@angular/router';

@Injectable()
export class AppcontentService extends RestService{

		constructor(
      http: Http,
      router: Router
    ) {
  		super(http, router);
		}

    returnNavigationTiers() {
      return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'tiers?client='+myGlobals.apiClient);
    }

    returnAutocompleteEntriesList() {
			return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'app/autocomplete/false?client='+myGlobals.apiClient);
    }

    returnPredefinedEntriesList() {
      return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'predefinedsearch?client='+myGlobals.apiClient);
    }

    putPredefinedEntry(PredefinedEntry) {
      return this.PutRequest(myGlobals.apiPath+myGlobals.apiVersion+'predefinedsearch/'+PredefinedEntry["name"]+'?client='+myGlobals.apiClient, PredefinedEntry);
    }

    postPredefinedEntry(PredefinedEntry) {
      return this.PostRequest(myGlobals.apiPath+myGlobals.apiVersion+'predefinedsearch?client='+myGlobals.apiClient, PredefinedEntry);
    }

    deletePredefinedEntry(PredefinedEntry) {
      return this.DeleteRequest(myGlobals.apiPath+myGlobals.apiVersion+'predefinedsearch/'+PredefinedEntry["name"]+'?client='+myGlobals.apiClient);
    }

    returnAppcontents() {
      return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'app?client='+myGlobals.apiClient);
    }

    postAppcontents(appContents) {
      return this.PutRequest(myGlobals.apiPath+myGlobals.apiVersion+'app?client='+myGlobals.apiClient, appContents);
    }

    returnButtonTexts() {
      return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'buttontexts?client='+myGlobals.apiClient);
    }

    postButtonText(buttonText) {
      return this.PostRequest(myGlobals.apiPath+myGlobals.apiVersion+'buttontexts?client='+myGlobals.apiClient, buttonText);
    }

    putButtonText(buttonText:Object) {
        return this.PutRequest(myGlobals.apiPath+myGlobals.apiVersion+'buttontexts/'+buttonText["text_id"]+'?client='+myGlobals.apiClient, buttonText);
    }

    returnTiers() {
      return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'tiers?client='+myGlobals.apiClient);
    }

    logError(err:string) {
        console.error('There was an error: ' + err);
    }

    returnCompetenceList() {
      return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'competences?client='+myGlobals.apiClient);
    }
}
