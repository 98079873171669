import { ModuleWithProviders }  from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from './common/logged-in.guard';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FoliodetailComponent } from './folio/foliodetail/foliodetail.component'
import { CompetenceComponent } from './competence/competence.component';
import { CompetencedetailComponent } from './competence/competencedetail/competencedetail.component';
import { ExportComponent } from './export/export.component';
import { ProductComponent } from './product/product.component';
import { SystemComponent } from './system/system.component';
import { DocumentComponent } from './document/document.component';
import { DocumentdetailComponent } from './document/documentdetail/documentdetail.component';
import { FolioComponent } from './folio/folio.component';
import { AppcontentComponent } from './appcontent/appcontent.component';
import { SettingsComponent } from './settings/settings.component';
import { DataprivacyComponent } from './dataprivacy/dataprivacy.component';
import { ImprintComponent } from './imprint/imprint.component';

export const appRoutes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [LoggedInGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [LoggedInGuard] },
  { path: 'folio', component: FolioComponent, canActivate: [LoggedInGuard] },
  { path: 'folio/:name', component: FoliodetailComponent, canActivate: [LoggedInGuard] },
  { path: 'competence', component: CompetenceComponent, canActivate: [LoggedInGuard] },
  { path: 'competence/:id', component: CompetencedetailComponent, canActivate: [LoggedInGuard] },
  { path: 'export', component: ExportComponent, canActivate: [LoggedInGuard] },
  { path: 'product', component: ProductComponent, canActivate: [LoggedInGuard] },
  { path: 'system', component: SystemComponent, canActivate: [LoggedInGuard] },
  { path: 'document', component: DocumentComponent, canActivate: [LoggedInGuard] },
  { path: 'document/:id/:type', component: DocumentdetailComponent, canActivate: [LoggedInGuard] },
  { path: 'document/:id', component: DocumentdetailComponent, canActivate: [LoggedInGuard] },
  { path: 'appcontent', component: AppcontentComponent, canActivate: [LoggedInGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [LoggedInGuard] },
  { path: 'dataprivacy', component: DataprivacyComponent },
  { path: 'imprint', component: ImprintComponent },
  { path: '**', redirectTo: '' }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
