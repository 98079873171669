export class Competence {
	constructor(
	public expertiseID?: string,
	public expertiseName?: string,
	public expertiseShortDescription?: string,
	public expertiseDescription?: string,
  public expertiseImage?: string,
  public order?:any,
  public links?: Array<Link>,
	public documents?: Array<Document>,
	public psfks?: Array<any>,
	public filters?: Array<any>
	) { }
}

export class Link {
  constructor(
		public title: string,
		public targetUrl: string) { }
}

export class Document {
	constructor(
		public folioLanguage: string,
		public folioProductID: string,
		public documentDate: string,
		public folioTitleImagpe: string,
		public folioTitle: string,
		public folioName: string,
		public folioShortDescription: string,
		public folioDocumentType: string,
		public isArticleInFolio: string,
		public articleTitle: string,
		public articleDate: string,
		public articleName: string,
		public printNumber: string){ }
}
