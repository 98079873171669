import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { DataTable, DataTableModule } from 'primeng/primeng';
import { FolioService } from '../../services/folio.service';
import { Folio } from '../folio.model';
import myGlobals = require('./../../globals');
import { Options } from 'selenium-webdriver/firefox';

@Component({
  moduleId: module.id,
  selector: 'app-foliolist',
  templateUrl: 'foliolist.component.html',
  styleUrls: ['foliolist.component.scss'],
  providers: [
    FolioService
  ],
  inputs: ['edit', 'refreshable', 'selectedFolios', 'single', 'showCover']
})

export class FoliolistComponent{

  public _myGlobals = myGlobals;
  public _myGlobalsStatusesLength: any;

  public edit: boolean = false;
  public refreshable: boolean = false;
  public selectedFolios: Folio[];
  public single: boolean;
  public chosenFilter: any = '';
  public showCover: boolean = false;

  @Output() chosenFolio = new EventEmitter();
  @ViewChild('dt') dataTable: DataTable;

  listOfFolios: Folio[];

  constructor(
    private _folioService: FolioService
  ) {
    this.returnFolioList();
    this._myGlobalsStatusesLength = Object.keys(this._myGlobals.statuses);
  }

  public getSelectedFolios() {
    return this.selectedFolios;
  }

  onFilter(e, dt) {
    localStorage.setItem('filters-foliolist', JSON.stringify(e));
    this.dataTable = dt;
  }

  private refreshStatus() {
    this.returnFolioList();
  }

  private resetFilters() {
    const options = document.querySelectorAll('.foliolist-state-select-options')
    for (let i = 0, iMax = options.length; i < iMax; i += 1) {
      (<any>options[i]).selected = false
    }
    this.dataTable.reset();
    localStorage.removeItem('filters-foliolist');
  }

  private returnFolio(folio: Folio) {
    this.chosenFolio.next(folio);
  }

  private toggleFolio(folio: Folio, event) {
    if (this.single) {
      for (var key in this.selectedFolios) {
        if (this.selectedFolios.hasOwnProperty(key)) {
          this.selectedFolios[key] = undefined;
        }
      }
      if (event.currentTarget.checked === true) {
        this.selectedFolios['folioLanguage'] = folio['folioLanguage'];
        this.selectedFolios['folioProductID'] = folio['folioProductID'];
        this.selectedFolios['documentDate'] = folio['documentDate'];
        this.selectedFolios['folioTitleImage'] = folio['folioCoverImage'];
        this.selectedFolios['folioTitle'] = folio['folioTitle'];
        this.selectedFolios['folioName'] = folio['folioName'];
        this.selectedFolios['folioShortDescription'] = folio['folioDescription'];
        this.selectedFolios['folioDocumentType'] = folio['folioDocumentType'];
        this.selectedFolios['isArticleInFolio'] = '0';
      } else if (event.currentTarget.checked === false) {
        this.selectedFolios = [];
      }
    } else if (!this.single) {
      if (event.currentTarget.checked === true) {
        this.selectedFolios.push(folio);
      } else if (event.currentTarget.checked === false) {
        for (var i = 0; i < this.selectedFolios.length; i++) {
          if (this.selectedFolios[i]['folioName'] === folio['folioName']) {
            this.selectedFolios.splice(i , 1);
          }
        }
      }
    }
  }

  private inAssignements(folio: Folio) {
    var checked = false;
    if (this.single) {
      if (this.selectedFolios['folioName'] === folio['folioName']){
        checked = true;
      }
    } else if (!this.single) {
      for (var i = 0; i < this.selectedFolios.length; i++) {
        if (this.selectedFolios[i]['folioName'] === folio['folioName']) {
          checked = true;
        }
        if(checked) break;
      }
    }
    return checked;
  }

  private getLastWord(words) {
    var n = words.split(' ');
    return n[n.length - 1];
  }

  private checkForAndApplyFilters() {
    let filters = localStorage.getItem('filters-foliolist');
    if (filters) {
      for (const [key, val] of (<any>Object).entries(JSON.parse(filters).filters)) {
        const col = key
        let value = ''
        if (val && (<any>val).value && typeof (<any>val).value === 'string') {
          value = (<any>val).value
        } else if (val && (<any>val).value && Array.isArray((<any>val).value)) {
          value = (<any>val).value[0]
        }

        if (key === 'STATUS' && value !== '') {
          const options = document.querySelectorAll('.foliolist-state-select-options')
          for (let i = 0, iMax = options.length; i < iMax; i += 1) {
            const optionValue = this.getLastWord((<any>options[i]).value)
            if (optionValue === value) {
              (<any>options[i]).selected = true
            }
          }
        }
        const mode = (val && (<any>val).matchMode) ? (<any>val).matchMode : ''
        this.dataTable.filter(value, col, mode);
      }
    }
  }

  private returnFolioList() {
    this._folioService.returnFolioList().subscribe(
    data => {
      console.log('HTTP-Status: ', data['0'].status);
      this.listOfFolios = data['0'].json;
      console.log(data['0'].json);
    },
      err => console.error(err),
      () => {
        console.log('done loading folios');
        this.checkForAndApplyFilters()
      }
    );
  }
}
