import { Component, OnInit } from '@angular/core';
import { Folio } from '../folio.model';

@Component({
  moduleId: module.id,
  selector: 'app-foliofinal',
  templateUrl: 'foliofinal.component.html',
  styleUrls: ['foliofinal.component.css'],
  inputs: ['folio']
})
export class FoliofinalComponent implements OnInit {

  public folio: Folio;

  constructor() {}

  ngOnInit() {
    if (this.folio && this.folio.articles !== undefined) {
      delete this.folio.articles;
    }
  }
}
