import { Component, OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-imprint',
  templateUrl: 'imprint.component.html',
  styleUrls: ['imprint.component.css']
})
export class ImprintComponent implements OnInit {

  constructor() {}

  ngOnInit() {
  }

}
