import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'folioType'
})
export class FolioTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let wmvs = value;
    // console.log(wmvs);
    // console.log(args);
    if (args == undefined) {
      return wmvs;
    } else {
      return wmvs.filter(wmv => wmv.type == args);
    }
    // return value;
  }

}
