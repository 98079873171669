import { Component, Output, AfterContentInit, EventEmitter, DoCheck } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { FolioArticle, Button, ButtonText } from '../folio.model';
import { Headers } from '@angular/http';
import myGlobals = require('./../../globals');
import {TooltipModule} from 'primeng/primeng';

@Component({
  moduleId: module.id,
  selector: 'app-folioarticle',
  templateUrl: 'folioarticle.component.html',
  styleUrls: ['folioarticle.component.css'],
  inputs: ['articles', 'type', 'single', 'sectionNr', 'folioType']
})

export class FolioarticleComponent implements AfterContentInit {

  public articles: FolioArticle[];
  public activeArticle: FolioArticle;
  public newButton: Button;
  public newText: ButtonText;
  public selectedBtnText: any;
  public type: string;
  public folioType: string;
  public single: boolean;
  public zipUpload: any;
  public sectionNr = '';
  public _FolioArticle:FolioArticle;
  public _myGlobals;
  public newRow:Object = {pageCount: null};
  public activeButton: string;
  public showNewRow: boolean = true;
  public headers: Headers;

  @Output() myEvent = new EventEmitter();
  @Output() myRefreshEvent = new EventEmitter();
  @Output() updateSectionsEvent = new EventEmitter();
  @Output() triggerFolioCheckEvent = new EventEmitter();

  constructor(private dragula: DragulaService) {
    this.activeArticle = new FolioArticle();
    this.newButton = new Button();
    this.newText = new ButtonText();
    this.newText.title = '';
    this.newText.htmlContent = '';
    this.selectedBtnText = {};
    this.newRow = {};
    this.zipUpload = null;
    this._myGlobals = myGlobals;
    this.dragula
      .dropModel()
      .subscribe(value => {
        if (value.sourceModel.length > 0 &&
          value.sourceModel.some( r => this.articles.includes(r))) {
          this.articles = value.sourceModel
          this.updateSectionsEvent.emit({
            sections: this.articles,
            articleIndex: this.sectionNr
          })
        } else if (value.sourceModel.length > 0 &&
          value.targetModel.some( r => this.articles.includes(r))) {
          this.articles = value.targetModel
          this.updateSectionsEvent.emit({
            sections: this.articles,
            articleIndex: this.sectionNr
          })
        }
      });
  }

  ngAfterContentInit():void {
    if (
      this.folioType === 'SingleFolio' &&
      this.articles &&
      this.articles.length > 0
    ) {
      this.newRow = {
        WMVID: this.articles[0].WMVID,
        printNumber: this.articles[0].printNumber,
        pageCount: this.articles[0].pageCount,
        date: this.articles[0].date,
        letzte_upload_aenderung: this.articles[0].letzte_upload_aenderung
      };
    } else if (
      this.folioType === 'Folder'
    ) {
      const newRow = {}
      this.newRow = Object.assign(newRow)
    }
  }

  ngDoCheck():void {
    if (
      this.articles &&
      this.articles.length &&
      this.articles[0].pageCount &&
      this.articles[0].pageCount !== this.newRow['pageCount'] &&
      this.folioType !== 'Folder'
    ) {
      this.newRow = {
        WMVID: this.articles[0].WMVID,
        printNumber: this.articles[0].printNumber,
        pageCount: this.articles[0].pageCount,
        date: this.articles[0].date,
        letzte_upload_aenderung: this.articles[0].letzte_upload_aenderung
      };
    }
  }

  public cleanButtons(buttons) {
    if (buttons !== undefined) {
      for (let i = buttons.length; i--;) {
        if (
          !buttons[i].buttonType ||
          !buttons[i].page ||
          (buttons[i].text_id_1 === '' && buttons[i].text_id_2 === '' && buttons[i].text_id_3 === '')
        ) {
          buttons.splice(i, 1);
        }
      }
    }
  }

  public removeButton(index:number) {
    this.activeArticle.buttons.splice(index, 1);
    this.myEvent.emit(true);
  }

  public removeText(buttonIndex:number, textIndex:number) {
    this.activeArticle.buttons[buttonIndex].texts.splice(textIndex, 1);
    this.myEvent.emit(true);
  }

  public removeLink(index:number) {
    this.articles.splice(index, 1);
  }

  public refreshDocument(index: number, WMVID: string) {
    this.myRefreshEvent.emit({
      index:index,
      WMVID:WMVID,
      sectionNr:this.sectionNr
    });
  }

  private addNewButton() {
    if (this.activeArticle.buttons === undefined) {
      this.activeArticle.buttons = [];
    }
    this.newButton = new Button();
    this.newButton.texts = [];
    this.newButton.texts[0] = new ButtonText();
    this.newButton.buttonType = 'Info';
    this.activeArticle.buttons.push(this.newButton);
  }

  private addNewText(index:number) {
    if (this.activeArticle.buttons[index].texts === undefined) {
      this.activeArticle.buttons[index].texts = [];
    }
    this.activeArticle.buttons[index].texts.push(this.newText);
    this.newText = new ButtonText();
    this.newText.title = '';
    this.newText.htmlContent = '';
  }

  public buttonTypes = [
    {
      name: 'Info',
      value: 'Info'
    },
    {
      name: 'Video',
      value: 'video'
    },
    {
      name: 'Brandschutz 2014',
      value: 'Brandschutz2014'
    },
    {
      name: '3D-Objekte',
      value: 'zip'
    }
  ];

  public addArticleToFolio(theArticle:any) {
    let articleAmount = 0;
    if (!this.articles) {
      this.articles = []
    }
    if (this.articles) {
      articleAmount = this.articles.length;
    }
    if (this.articles && articleAmount > 0 && this.articles[articleAmount-1].WMVID === '') {
      this.articles.splice(articleAmount - 1, 0, theArticle);
    } else if (this.articles) {
      this.articles.push(theArticle); //breaks folder folios right now
    }
    this.newRow = {};
    if (this.articles && this.folioType === 'SingleFolio' && articleAmount > 0 && this.articles[0].date) {
      this.newRow = {
        WMVID: this.articles[0].WMVID,
        printNumber: this.articles[0].printNumber,
        pageCount: this.articles[0].pageCount,
        date: this.articles[0].date,
        letzte_upload_aenderung: this.articles[0].letzte_upload_aenderung
      };
    }
    this.myEvent.emit(true);
  }

  triggerFolioCheck(event) {
    this.triggerFolioCheckEvent.emit(event);
  }

  onBeforeSend(event) {
    this.headers = new Headers();
    const token = localStorage.getItem('auth_token');
    event.xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  }

  onUpload(event, buttonIndex, textIndex) {
    const response = JSON.parse(event.xhr.responseText);
    this.activeArticle.buttons[buttonIndex].texts[textIndex].url = response.assetPath;
    this.zipUpload = response;
  }
}
