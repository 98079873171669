import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { RestService } from './rest.service';
import myGlobals = require('./../globals');
import { Router } from '@angular/router';

@Injectable()
export class ProductService extends RestService {

    constructor(
      http: Http,
      router: Router
    ) {
      super(http, router);
    }

    returnProductList() {
      return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'products?client='+myGlobals.apiClient);
    }

    postProductList() {
      return this.PostRequest(myGlobals.apiPath+myGlobals.apiVersion+'products/updateFromCMS?client='+myGlobals.apiClient, {});
    }
}
