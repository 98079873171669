import { Component, Output, EventEmitter } from '@angular/core';
import { ButtonText } from './../appcontent.model';

import { AppcontentService } from './../../services/appcontent.service';

@Component({
  moduleId: module.id,
  selector: 'app-appbuttonlist',
  templateUrl: 'appbuttonlist.component.html',
  styleUrls: ['appbuttonlist.component.css'],
  providers: [AppcontentService],
  inputs: ['selectedBtnText', 'activeButton']
})
export class AppbuttonlistComponent{

  public listOfButtonTexts: Array<ButtonText>;
  public selectedBtnText: any;
  public activeButton: string;

  constructor(
    private _appcontentService: AppcontentService
    ){
      this.returnButtonTexts();
    }

  private toggleBtnText(buttonText: ButtonText, event){
    if(event.currentTarget.checked == true){
      this.selectedBtnText[this.activeButton] = buttonText.text_id;
    }else if(event.currentTarget.checked == false){
      this.selectedBtnText[this.activeButton] = undefined;
    }
  }

  private inAssignements(buttonText: ButtonText){
    var i;
    var checked = false;
    if(this.selectedBtnText[this.activeButton] === buttonText.text_id){
      checked = true;
    }
    return checked;
  }

  returnButtonTexts() {
      this._appcontentService.returnButtonTexts().subscribe(
          data => {
            // console.log("HTTP-Status: ", data["0"].status);
              this.listOfButtonTexts = data["0"].json ;
              // console.log(this.listOfButtonTexts);
          },
          err => console.error(err),
          () => console.log('done loading Buttontexts')
      );
  }
}
