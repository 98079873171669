export class AutocompleteEntry{
  constructor(
	public entry?: string
	){}
}

export class PredefinedEntry{
  constructor(
	public name?: string,
  public psfks?: Array<any>
	){}
}

export class Tier{
  constructor(
    public label?: string,
    public data?: string,
    public children?: Array<Tier>
  ){}
}

export class AppTexts{
  constructor(
	public title?: string,
  public subtitle?: string
	){}
}

export class Hightlight{
  constructor(
	public order?: number,
  public path?: string
	){}
}

export class ButtonText{
  constructor(
	public text_id?: string,
  public headline?: string,
  public text?: string
	){}
}
