import { Component, Output, EventEmitter } from '@angular/core';
// import { FolioDetailService } from '../../services/foliodetail.service';
import { FolioService } from '../../services/folio.service';
import { Folio, FolioArticle } from '../folio.model';

@Component({
  moduleId: module.id,
  selector: 'app-articlelist',
  templateUrl: 'articlelist.component.html',
  styleUrls: ['articlelist.component.css'],
  providers: [
    // FolioDetailService
    FolioService
  ],
  inputs: ['selectedArticle']
})

export class ArticlelistComponent{

  public selectedArticle: Folio[];
  public single: boolean;
  @Output() chosenArticle = new EventEmitter();

	// listOfFolios: Folio[];
  listOfArticles: Array<any>;

  constructor(
	  //  private _folioDetailService: FolioDetailService
     private _folioService: FolioService
   ) {
	      this.returnArticleList();
        console.log("selectedFolio"+ this.selectedArticle);
  }

  public getSelectedArticle(){
    return this.selectedArticle;
  }

  private toggleArticle(article: any, event){
    console.log(event.currentTarget.checked);
    if(event.currentTarget.checked == true){
      this.selectedArticle["articleName"] = article["articleName"];
      this.selectedArticle["articleTitle"] = article["articleTitle"];
      this.selectedArticle["articleDate"] = article["articleDate"];
      this.selectedArticle["isArticleInFolio"] = "1";
    }else if(event.currentTarget.checked == false){
      this.selectedArticle["articleName"] = "";
      this.selectedArticle["articleTitle"] = "";
      this.selectedArticle["articleDate"] = "";
      this.selectedArticle["isArticleInFolio"] = "0";
    }
  }

  private inAssignements(article: any){
    var i;
    var checked = false;

    if(this.selectedArticle["articleName"] === article["articleName"]){
      checked = true;
    }

    return checked;
  }

  private returnArticleList() {
    this._folioService.returnFolioList().subscribe(
		data => {
        console.log("HTTP-Status: ", data["0"].status);
        this.listOfArticles = data["0"].json;
        console.log(data["0"].json)
      },
      err => console.error(err),
      () => console.log('done loading foliodetail')
    );
  }

}
