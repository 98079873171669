import { Component, ViewChild } from '@angular/core';
import { FolioService } from '../services/folio.service';
import { Router } from '@angular/router';
import { Folio } from './folio.model';
import { FoliolistComponent } from './foliolist/foliolist.component';

@Component({
  moduleId: module.id,
  selector: 'app-folio',
  providers: [
    FolioService
  ],
  templateUrl: 'folio.component.html',
  styleUrls: ['folio.component.scss']
})

export class FolioComponent {

  listOfFolios: Folio[];

  @ViewChild(FoliolistComponent) _folioList: FoliolistComponent;

  constructor(
    private _router: Router
  ) {}

  gotoDetail(folio:Folio) {
    let link = ['/folio/' + folio.folioName];
    this._router.navigate(link);
  }

  createFolio() {
    this._router.navigate(['/folio/new']);
  }
}
