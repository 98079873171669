import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { RestService } from './rest.service';
import myGlobals = require('./../globals');
import { Router } from '@angular/router';
import { User } from '../settings/users.model';

@Injectable()
export class UsersService extends RestService {

  constructor(
    http: Http,
    router: Router
  ) {
    super(http, router);
  }

  returnUsersList(){
    return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'users?client='+myGlobals.apiClient);
  }

  addUser(user: User){
    return this.PostRequest(myGlobals.apiPath+myGlobals.apiVersion+'users?client='+myGlobals.apiClient, user);
  }

  deleteUser(user: User){
    return this.DeleteRequest(myGlobals.apiPath+myGlobals.apiVersion+'users/'+user["email"]+'?client='+myGlobals.apiClient);
  }

  updateUser(user: User, newPW: string){
    return this.PutRequest(myGlobals.apiPath+myGlobals.apiVersion+'users/'+user["email"]+'?client='+myGlobals.apiClient, {email: user.email, password: newPW});
  }
}
