export class Document {
	constructor(
	public wmv_id?: string,
	public sprache?: string,
	public type?: string,
	public art?: string,
	public ausgabe?: string,
  public downloadlink?: string,
  public drucknr?: string,
  public ds_format?: string,
  public dokumenttitel?: string,
  public letzte_upload_aenderung?: any,
  public STATUS?: string,
  public pdffile?: string,
  public pageCount?: string
	) { }
}
