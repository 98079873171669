import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameTaken'
})
export class NameTakenPipe implements PipeTransform {

  transform(value: any, arg1: string, arg2:Array<any>, arg3: string): any {
    if(arg3 == "new" && arg1 != "" && arg2.length != -1){
      for(let i = 0; i < arg2.length; i++) {
        for (var key in arg2[i]) {
          if(arg1 == arg2[i].folioName){
            return true;
          }else if(arg1 == arg2[i].expertiseID){
            return true;
          }else if(arg1 == arg2[i].wmv_id){
            return true;
          }else if(arg1 == arg2[i].drucknr){
            return true;
          }
        }
      }
    }

    return null;
  }

}
