import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import {RestService} from './rest.service';
import myGlobals = require('./../globals');
import { Router } from '@angular/router';

@Injectable()
export class ExportService extends RestService{

    constructor(
      http: Http,
      router: Router
    ) {
      super(http, router);
    }

    returnFTPLog() {
			return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'dataversions?client='+myGlobals.apiClient);
    }

    returnUploadstatus(status) {
      return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'folios/status/'+status+'?client='+myGlobals.apiClient);
    }

    postAppContent() {
      return this.PostRequest(myGlobals.apiPath+myGlobals.apiVersion+'app/export'+'?client='+myGlobals.apiClient, {});
    }

    logError(err:string) {
        console.error('There was an error: ' + err);
    }

}
