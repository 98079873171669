import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { RestService } from './rest.service';
import myGlobals = require('./../globals');
import { Router } from '@angular/router';

@Injectable()
export class CompetencedetailService extends RestService{

    constructor(
      http: Http,
      router: Router
    ) {
      super(http, router);
    }

    returnCompetenceDetail(id:string) {
        console.log("Getting competence details for ", id);
        return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'competences/'+id+'?client='+myGlobals.apiClient);
    }

    postCompetenceDetail(competence:Object) {
      return this.PostRequest(myGlobals.apiPath+myGlobals.apiVersion+'competences?client='+myGlobals.apiClient, competence);
		}

    putCompetenceDetail(competence:Object) {
      return this.PutRequest(myGlobals.apiPath+myGlobals.apiVersion+'competences/'+competence["expertiseID"]+'?client='+myGlobals.apiClient, competence);
    }
}
