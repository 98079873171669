import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Document } from './document.model';
import { WmvService } from '../services/wmv.service';

@Component({
  moduleId: module.id,
  selector: 'app-document',
  templateUrl: 'document.component.html',
  styleUrls: ['document.component.css'],
  providers: [ WmvService ]

})
export class DocumentComponent{

  public alerts:Array<Object> = [];
  public showUnusedDocuments: boolean = false;

  constructor(
    private _router: Router,
    private _wmvService: WmvService
  ){}

  public addSuccessAlert():void {
    this.alerts = [];
    this.alerts.push({
      msg: 'Alle Dokumente wurden erfolgreich geupdated', 
      type: 'success', 
      closable: true, 
      timeout: 3000
    });
  }

  public addErrorAlert():void {
    this.alerts = [];
    this.alerts.push({
      msg: 'Beim Update ist eine Fehler aufgetreteten', 
      type: 'danger', 
      closable: true, 
      timeout: 3000
    });
  }

  gotoDetail(document:Document) {
    let link = ['/document/' + document.wmv_id + '/' + document.type.toLowerCase()];
    this._router.navigate(link);
  }

  createDocument() {
    this._router.navigate(['/document/new']);
  }

  toggleUnusedDocuments() {
    this.showUnusedDocuments = !this.showUnusedDocuments;
    console.log('Toggle')
  }

  // 1. Update Documents from CMS
  // 2. Downloads PDFs
  // 3. Analyze PDFs
  updateDocuments() {
    this.alerts.push({
      msg: 'Updatevorgang gestartet', 
      type: 'warning', 
      closable: true
    });
    this._wmvService.postUpdateFromCMS().subscribe(
      data => {
        console.log('status' + data);
      },
      err => {
        console.error(err);
        this.addErrorAlert();
      },
      () => {
        console.log('Updated Documents from CMS');
        this.downloadPDFs();
      }
    );
  }

  downloadPDFs() {
    this._wmvService.postDownloadPDFs().subscribe(
      data => {
        console.log('status' + data);
      },
      err => {
        console.error(err);
        this.addErrorAlert();
      },
      () => {
        console.log('downloaded PDFs');
        this.analyzePDFs();
      }
    );
  }

  analyzePDFs() {
    this._wmvService.postAnalyzePDFs().subscribe(
      data => {
        console.log('status' + data);
      },
      err => {
        console.error(err);
        this.addErrorAlert();
      },
      () => {
        console.log('Analyzed PDFs');
        this.addSuccessAlert();
      }
    );
  }
  
  updateHTMLFolios() {
    this.alerts.push({
      msg: 'Updatevorgang gestartet',
      type: 'warning',
      closable: true
    });
    this._wmvService.postUpdateFromWordpress().subscribe(
      data => {
        console.log('status' + data);
      },
      err => {
        console.error(err);
        this.addErrorAlert();
      },
      () => {
        console.log('Updated Documents from Wordpress');
        this.downloadWordpress();
      }
    );
  }

  downloadWordpress() {
    this._wmvService.postDownloadWordpress().subscribe(
      data => {
        console.log('status' + data);
      },
      err => {
        console.error(err);
        this.addErrorAlert();
      },
      () => {
        console.log('downloaded HTML Files from Wordpress');
        this.addSuccessAlert();
      }
    );
  }
}
