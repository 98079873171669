import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

import 'dragula';
import 'zone.js';
import { ViewEncapsulation } from '@angular/core';

import { UserService } from './services/user.service';
import { LoggedInGuard } from './common/logged-in.guard';


@Component({
  encapsulation: ViewEncapsulation.None,
  moduleId: module.id,
  selector: 'kifvs-frontend-cli-app',
  templateUrl: 'kifvs-frontend-cli.component.html',
  styleUrls: ['kifvs-frontend-cli.component.scss']
})

export class KifvsFrontendCliAppComponent {
  title = 'Knauf Infothek Folioverwaltungssystem';
  public isLoggedIn: any;
  public userService: any;
  public router: Router;

  private viewContainerRef:ViewContainerRef;

  public constructor(
    viewContainerRef: ViewContainerRef,
    _loggedInGuard: LoggedInGuard,
    _userService: UserService,
    _router: Router
  ) {
    this.isLoggedIn = _loggedInGuard;
    this.userService = _userService;
    this.router = _router;
    // You need this small hack in order to catch application root view container ref
    this.viewContainerRef = viewContainerRef;
  }

  logout(){
    this.router.navigate(['/login']);
    this.userService.logout();
  }
}
