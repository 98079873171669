import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import {RestService} from './rest.service';
import myGlobals = require('./../globals');
import { Router } from '@angular/router';

@Injectable()
export class CompetenceService extends RestService{

  constructor(
    http: Http,
    router: Router
  ) {
    super(http, router);
  }

    returnCompetenceList() {
        return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'competences?client='+myGlobals.apiClient);
    }

    deleteCompetence(competence:Object) {
      return this.DeleteRequest(myGlobals.apiPath+myGlobals.apiVersion+'competences/'+competence["expertiseID"]+'?client='+myGlobals.apiClient);
    }

    // postCompetenceDetail(competence:Object) {
    //   return this.PostRequest(myGlobals.apiPath+myGlobals.apiVersion+'competences?client='+myGlobals.apiClient, {});
    // }

    logError(err:string) {
        console.error('There was an error: ' + err);
    }
}
