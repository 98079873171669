import {environment} from './environment';

export var apiPath;
export var System;

if (environment.envName === 'production') {
  // apiPath = 'https://backend.knauf-infothek.de/kifvs/'
  apiPath = 'https://backend.prd.kifvs.knauf-infothek.de/'
  System = 'de.knauf.infothek';
} else if (environment.envName === 'dev') {
  // apiPath = 'https://backend.test.knauf-infothek.de/kifvs/'
  apiPath = 'https://backend.dev.kifvs.knauf-infothek.de/'
  System = 'de.knauf.infothek'
} else if (environment.envName === 'ci') {
  apiPath = 'https://backend.test.knauf-infothek.de/kifvs/'
  System = 'de.knauf.infothek';
}

export var apiVersion = 'v1/';
export var apiClient = 'x1';

export var PDFstatuses = {
  '': {
    translation: 'Alle',
    color: '#000',
    selected: true
  },
  NEW: {
    translation: 'Neu',
    color: '#000'
  },
  NEEDS_UPDATE: {
    translation: 'Aktualisierung ausstehend',
    color: '#d78e27',
  },
  PDF_DOWNLOADED: {
    translation: 'PDF heruntergeladen',
    color: '#d78e27',
  },
  PDF_OK: {
    translation: 'PDF geprüft',
    color: '#00b900',
  },
  ERROR_PDF_DOWNLOAD: {
    translation: 'Fehler beim Download',
    color: '#cc0000',
  },
  ERROR_PARSING_PDF: {
    translation: 'Fehler bei der Prüfung',
    color: '#cc0000',
  },
  PDF_UPLOADED: {
    translation: 'PDF hochgeladen',
    color: '#d78e27',
  },
  ERROR_PDF_UPLOAD: {
    translation: 'Fehler beim Hochladen',
    color: '#cc0000',
  },
  HTML_DOWNLOADED: {
    translation: 'HTML heruntergeladen',
    color: '#00b900',
  },
  ERROR_HTML_DOWNLOAD: {
    translation: 'Fehler beim Herunterladen',
    color: '#cc0000',
  }
};

export var statuses = {
  '': {
    translation: 'Alle',
    color: '#000',
    selected: true
  },
  NEW: {
    translation: 'Neu',
    color: '#000',
    action: 'CREATE',
    actionTranslation: 'Generieren'
  },
  NEEDS_UPDATE: {
    translation: 'Aktualisierung ausstehend',
    color: '#d78e27',
    action: 'CREATE',
    actionTranslation: 'Generieren'
  },
  NEEDS_UPDATE_MIGRATED_STRUCTURE: {
    translation: 'Aktualisierung ausstehend (Struktur aktualisiert)',
    color: '#d78e27',
    action: 'CREATE',
    actionTranslation: 'Generieren'
  },
  CREATING_FOLIO: {
    translation: 'Generierung (in Arbeit)',
    color: '#d78e27',
    blocked: true
  },
  FOLIO_CREATED: {
    translation: 'Generierung erfolgreich',
    color: '#00b900',
    action: 'dpsupload',
    actionTranslation: 'Übermitteln'
  },
  CREATED_WITH_WARNINGS: {
    translation: 'Generierung erfolgreich (mit Warnungen)',
    color: '#00b900',
    action: 'dpsupload',
    actionTranslation: 'Übermitteln'
  },
  ERROR_CREATING: {
    translation: 'Generierung fehlgeschlagen',
    color: '#cc0000',
    action: 'CREATE',
    actionTranslation: 'Erneut generieren'
  },
  UPLOADING: {
    translation: 'Übermittlung',
    color: '#d78e27',
    blocked: true
  },
  UPLOADED: {
    translation: 'Übermittlung erfolgreich',
    color: '#00b900',
    action: 'publish',
    actionTranslation: 'Veröffentlichen'
  },
  ERROR_UPLOADING: {
    translation: 'Übermittlung fehlgeschlagen',
    color: '#cc0000',
    action: 'dpsupload',
    actionTranslation: 'Erneut Übermitteln'
  },
  IN_PUBLISHING_QUEUE: {
    translation: 'Veröffentlichung (in Arbeit)',
    color: '#d78e27',
    blocked: true
  },
  PUBLISHED: {
    translation: 'Veröffentlicht',
    color: '#00b900',
    action: 'unpublish',
    actionTranslation: 'Zurückziehen'
  },
  PUBLISHED_MIGRATED_STRUCTURE: {
    translation: 'Veröffentlicht (Struktur aktualisiert)',
    color: '#00b900',
    action: 'unpublish',
    actionTranslation: 'Zurückziehen'
  },
  ERROR_PUBLISHING: {
    translation: 'Veröffentlichen fehlgeschlagen',
    color: '#cc0000',
    action: 'publish',
    actionTranslation: 'Erneut Veröffentlichen'
  },
  UPDATE: {
    translation: 'Aktualisierung',
    color: '#000',
    action: 'CREATE',
    actionTranslation: 'Generieren'
  },
  ERROR: {
    translation: 'Fehler',
    color: '#cc0000'
  },
  TO_DELETE: {
    translation: 'wird zurückgezogen',
    color: '#d78e27'
  },
  DELETED: {
    translation: 'Veröffentlichung zurückgezogen',
    color: '#cc0000'
  },
  DELETED_MIGRATED_STRUCTURE: {
    translation: 'Veröffentlichung zurückgezogen (Struktur aktualisiert)',
    color: '#cc0000'
  }
};
