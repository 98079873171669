import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import myGlobals = require('./../globals');

@Injectable()
export class UserService {
  private loggedIn = false;
  private user: string;
  private isAdmin: boolean;

  constructor(private http: Http) {
    this.loggedIn = !!localStorage.getItem('auth_token');
  }

  public login(email, password) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http
      .post(
        myGlobals.apiPath+myGlobals.apiVersion+'login?client='+myGlobals.apiClient,
        JSON.stringify({ "email": email, "password": password }),
        { headers }
      )
      .map(res => res.json())
      .map((res) => {
        if (res.success) {
          localStorage.setItem('auth_token', res.auth_token);
          localStorage.setItem('user', email);
          this.loggedIn = true;
          this.user = email;
        }

        return res.success;
      });
  }

  public logout() {
    localStorage.removeItem('auth_token');
    this.loggedIn = false;
    this.user = undefined;
  }

  public returnAdmin(){

    const admins = [
      "jochen.fehlbaum@knauf.com",
      "fehlbaum.jochen@knauf.com",
      "digitalmarketing@knauf.com"
    ];

    if(!!localStorage.getItem('user') && admins.indexOf(localStorage.getItem('user').toLowerCase()) !== -1){
      this.isAdmin = true;
    }else{
      this.isAdmin = false;
    }
    return this.isAdmin;
  }

  public returnUser(){
    if(!!localStorage.getItem('user')) {
      return localStorage.getItem('user');
    }
  }

}
