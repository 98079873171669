import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WmvService } from '../../services/wmv.service';
import { Document } from './../document.model';
import { Headers } from '@angular/http';
import myGlobals = require('./../../globals');
import { stringify } from '@angular/compiler/src/util';

@Component({
  moduleId: module.id,
  selector: 'app-documentdetail',
  templateUrl: 'documentdetail.component.html',
  styleUrls: ['documentdetail.component.css'],
  providers: [ WmvService ]
})

export class DocumentdetailComponent{

  public document: Document;
  public _allDocuments: Array<Document>;
  public headers: Headers;
  filteredLanguages: any[];
  filteredDocumentTypes: any[];
  filteredFormats: any[];
  filteredTypes: any[];
  public _myGlobals;
  public alerts:Array<Object> = [];
  private sub: any;
  public products: Array<Object> = [];
  public systems: Array<Object> = [];

  types: string[] = ['PDF', 'HTML'];
  languages: string[] = ['Deutsch', 'English', 'Französisch'];
  documentTypes: string[] = [
    'Broschüre',
    'Flyer',
    'Merkblatt',
    'Prospekt',
    'Florpost',
    'Technisches Blatt',
    'Detailblatt',
    'Technische Broschüre',
    'Broschüre lang DIN',
    'Technische Information',
    'Montageanleitung',
    'Anleitungen',
    'Magazin'
  ];
  formats: string[] = ['A4', 'DIN Lang'];
  private deCalendar: any = {
    firstDayOfWeek: 0,
    dayNames: [ 'Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag','Sonntag' ],
    dayNamesShort: [ 'Mo','Di','Mi','Do','Fr','Sa','So' ],
    dayNamesMin: [ 'Mo','Di','Mi','Do','Fr','Sa','So' ],
    monthNames: [ 'Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember' ],
    monthNamesShort: [ 'Jan','Feb','Mär','Apr','Mai','Jun','Jul','Aug','Sep','Oct','Nov','Dez' ]
  };


  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      let id = params['id'];
      if (id !== 'new') {
        let type = params['type'];
        this.returnDocumentDetail(id, type);
      }
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _WmvService: WmvService
  ) {
    this.document = new Document();
    this._myGlobals = myGlobals;
    this.document.STATUS = 'NEW';
    this.document.wmv_id = '';
    this.document.drucknr = '';
    this.getAllDocumentNames();
    this.products = [];
    this.systems = [];
  }

  private onCancel() {
    let link = ['/document'];
    this._router.navigate(link);
  }

  private saveDocumentDetail() {
    let name =  this.route.snapshot.params['id'];
    if (name === 'new') {
      this.postDocumentDetail();
    } else {
      this.putDocumentDetail();
    }
  }

  public inputFields: Array<any> = [
    {
      name: 'WMV-ID *',
      identity: 'wmv_id',
      type: 'text',
      disabled: true,
      required: true,
      duplicatecheck: true
    },
    {
      name: 'Druck Nr *',
      identity: 'drucknr',
      type: 'text',
      disabled: true,
      required: true,
      duplicatecheck: true
    },
    {
      name: 'Titel *',
      identity: 'dokumenttitel',
      type: 'text',
      disabled: false,
      required: true
    },
    {
      name: 'Typ *',
      identity: 'type',
      type: 'select',
      suggestions: this.filteredTypes,
      required: true
    },
    {
      name: 'Sprache',
      identity: 'sprache',
      type: 'select',
      suggestions: this.filteredLanguages,
      required: false
    },
    {
      name: 'Dokument Typ',
      identity: 'art',
      type: 'select',
      suggestions: this.filteredDocumentTypes,
      required: false},
    {
      name: 'Format',
      identity: 'ds_format',
      type: 'select',
      suggestions: this.filteredFormats,
      required: false
    },
    {
      name: 'Ausgabe (Format: TT.MM.JJJJ)',
      identity: 'ausgabe',
      type: 'text',
      disabled: false,
      required: false
    },
  ];

  filter(event, fieldType) {
    switch(fieldType) {
    case 'type':
      this.filteredTypes = [];
      for (let i = 0; i < this.types.length; i++) {
        let type = this.types[i];
        if (type.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
          this.filteredTypes.push(type);
        }
      }
      break;
    case 'sprache':
      this.filteredLanguages = [];
      for (let i = 0; i < this.languages.length; i++) {
        let language = this.languages[i];
        if (language.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
          this.filteredLanguages.push(language);
        }
      }
      break;
    case 'art':
      this.filteredDocumentTypes = [];
      for (let i = 0; i < this.documentTypes.length; i++) {
        let documentType = this.documentTypes[i];
        if (documentType.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
          this.filteredDocumentTypes.push(documentType);
        }
      }
      break;
    case 'ds_format':
      this.filteredFormats = [];
      for (let i = 0; i < this.formats.length; i++) {
        let formats = this.formats[i];
        if (formats.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
          this.filteredFormats.push(formats);
        }
      }
      break;
    }
  }

   handleDropdownClick(event, fieldType) {
    switch(fieldType) {
    case 'type':
      this.filteredTypes = [];
      setTimeout(() => {
        this.filteredTypes = this.types;
      }, 100)
      break;
    case 'sprache':
      this.filteredLanguages = [];
      setTimeout(() => {
        this.filteredLanguages = this.languages;
      }, 100)
      break;
    case 'art':
      this.filteredDocumentTypes = [];
      setTimeout(() => {
        this.filteredDocumentTypes = this.documentTypes;
      }, 100)
      break;
    case 'ds_format':
      this.filteredFormats = [];
      setTimeout(() => {
        this.filteredFormats = this.formats;
      }, 100)
      break;
    }
  }

  public addSuccessAlert():void {
    this.alerts.push({
      msg: 'Erfolgreich gespeichert', 
      type: 'success', 
      closable: true
    });
  }

  public addErrorAlert():void {
    this.alerts.push({
      msg: 'Beim Speichern ist eine Fehler aufgetreteten', 
      type: 'danger', 
      closable: true
    });
  }

  private getAllDocumentNames(){
    console.log('getAllFolioNames called');
    this._WmvService.returnWmvList().subscribe(
      data => {
        console.log('HTTP-Status: ', data['0'].status); this._allDocuments =  data['0'].json
      },
      err => console.error(err),
      () => console.log ('Ok got the folio: ', this._allDocuments)
    );
  }

  private returnDocumentDetail(id:string, type:string) {
    this._WmvService.returnDocumentDetail(id, type).subscribe(
      data => {
        console.log('HTTP-Status ', data['0'].status);
        this.document = data['0'].json;
        console.log(this.document);
        this.document.letzte_upload_aenderung = new Date(this.document.letzte_upload_aenderung);
      },
      err => console.error(err),
      () => console.log('done loading documents')
    );
    this._WmvService.returnProductsByWmvId(id).subscribe(
      data => {
        console.log('PRODUCTS___ ', data['0'].json);
        this.products = data['0'].json;
      },
      err => console.error(err),
      () => console.log('done loading products')
    );
    this._WmvService.returnSystemsByWmvId(id).subscribe(
      data => {
        console.log('SYSTEMS___ ', data['0'].json);
        this.systems = data['0'].json;
      },
      err => console.error(err),
      () => console.log('done loading products')
    );
  }

  private postDocumentDetail() {
    this._WmvService.postDocumentDetail(this.document).subscribe(
      data => {
        var result:Object = data['0'].json;
        console.log('status'+data);
      },
      err => {
        console.error(err);
        this.addErrorAlert();
      },
      () => {
        console.log('Created Document');
        this.addSuccessAlert();
        let link = ['/document/' + this.document.wmv_id + '/' + this.document.type.toLowerCase()];
        this._router.navigate(link);
      }
    );
  }

  private putDocumentDetail() {
    this._WmvService.putDocumentDetail(this.document).subscribe(
      data => {
        var result:Object = data['0'].json;
        console.log('status'+data[0].status);
      },
      err => {
        console.error(err);
        this.addErrorAlert();
      },
      () => {
        console.log('Saved Document changes');
        let id =  this.route.snapshot.params['id'];
        let type = this.route.snapshot.params['type'];
        this.returnDocumentDetail(id, type);
        this.addSuccessAlert();
      }
    );
  }

  private deleteDocument() {
    this._WmvService.deleteDocument(this.document).subscribe(
      data => {
        console.log('status'+data);
        this.onCancel()
      },
      err => {
        console.error(err);
      },
      () => {
        console.log('Document deleted');
      }
    );
  }

  onUpload(event) {
    let response = JSON.parse(event.xhr.responseText);
    this.returnDocumentDetail(this.document.wmv_id, this.document.type);
  }

  onBeforeSend(event) {
    this.headers = new Headers();
    let token = localStorage.getItem('auth_token');
    event.xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  }

}
