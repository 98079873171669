import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import {RestService} from './rest.service';
import myGlobals = require('./../globals');
import { Router } from '@angular/router';

@Injectable()
export class FolioDetailService extends RestService{

  constructor(
    http: Http,
    router: Router
  ) {
    super(http, router);
  }

  returnFolioDetail(folioName:string){
      return this.GetRequest(myGlobals.apiPath+myGlobals.apiVersion+'folios/'+folioName+'?client='+myGlobals.apiClient);
  }

  postFolioDetail(folio:Object) {
      return this.PostRequest(myGlobals.apiPath+myGlobals.apiVersion+'folios?client='+myGlobals.apiClient, folio);
  }

  putFolioDetail(folio:Object) {
      return this.PutRequest(myGlobals.apiPath+myGlobals.apiVersion+'folios/'+folio["folioName"]+'?client='+myGlobals.apiClient, folio);
  }

  putFolioStatus(folio:Object, status:string) {
      return this.PutRequest(myGlobals.apiPath+myGlobals.apiVersion+'folios/'+folio["folioName"]+'/status/'+status+'?client='+myGlobals.apiClient, folio);
  }

  postFolioProduce(folio:Object) {
      return this.PostRequest(myGlobals.apiPath+myGlobals.apiVersion+'folios/'+folio["folioName"]+'/produce?client='+myGlobals.apiClient, {});
  }

  deleteFolioMessage(folio:Object) {
    return this.DeleteRequest(myGlobals.apiPath+myGlobals.apiVersion+'folios/'+folio["folioName"]+'/messages?client='+myGlobals.apiClient);
  }

  postFolioStatus(folio:Object, status) {
      if(status == "unpublish"){
        return this.DeleteRequest(myGlobals.apiPath + myGlobals.apiVersion + 'folios/' + folio["folioName"] + '/delete?client=' + myGlobals.apiClient);
      }else if(status == "dpsupload") {
        return this.PostRequest(myGlobals.apiPath + myGlobals.apiVersion + 'folios/' + folio["folioName"] + '/upload?client=' + myGlobals.apiClient, {});
      }else{
        return this.PostRequest(myGlobals.apiPath + myGlobals.apiVersion + 'folios/' + folio["folioName"] + '/' + status + '?client=' + myGlobals.apiClient, folio);
      }
  }
}
