import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule  } from '@angular/platform-browser';
import { enableProdMode } from '@angular/core';
import { routing } from './app.routes';
import { KifvsFrontendCliAppComponent, environment } from './';
import { UserService } from './services/user.service';
import { LoggedInGuard } from './common/logged-in.guard';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FoliodetailComponent } from './folio/foliodetail/foliodetail.component';
import { FoliolistComponent } from './folio/foliolist/foliolist.component';
import { ArticlelistComponent } from './folio/articlelist/articlelist.component';
import { FolioarticleComponent } from './folio/folioarticle/folioarticle.component';
import { FoliofinalComponent } from './folio/foliofinal/foliofinal.component';
import { CompetenceComponent } from './competence/competence.component';
import { CompetencedetailComponent } from './competence/competencedetail/competencedetail.component';
import { CompetencelistComponent } from './competence/competencelist/competencelist.component';
import { ExportComponent } from './export/export.component';
import { ProductComponent } from './product/product.component';
import { SystemComponent } from './system/system.component';
import { DocumentComponent } from './document/document.component';
import { DocumentdetailComponent } from './document/documentdetail/documentdetail.component';
import { FolioComponent } from './folio/folio.component';
import { AppcontentComponent } from './appcontent/appcontent.component';
import { WmvlistComponent } from './wmvlist/wmvlist.component';
import { AppbuttonlistComponent } from './appcontent/appbuttonlist/appbuttonlist.component';
import { SettingsComponent } from './settings/settings.component';
import { DataprivacyComponent } from './dataprivacy/dataprivacy.component';
import { ImprintComponent } from './imprint/imprint.component';

import { MINMAX_DIRECTIVES } from './minmax/index';
//pipes
import { NameTakenPipe } from "./pipes/name-taken.pipe";
import { FolioTypePipe } from './pipes/folio-type.pipe';

//3rd party
import { ModalModule, TabsModule, AlertModule} from 'ngx-bootstrap';
import { DataTableModule, SharedModule, AutoCompleteModule, ButtonModule, FileUploadModule, TreeModule, CalendarModule, GrowlModule, PaginatorModule } from 'primeng/primeng';
import { DragulaModule } from 'ng2-dragula';
import { DragulaService } from 'ng2-dragula';
import { ChartsModule } from 'ng2-charts';

if (environment.production) {
  enableProdMode();
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    FormsModule,
    routing,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AlertModule.forRoot(),
    DataTableModule,
    PaginatorModule,
    SharedModule,
    AutoCompleteModule,
    FileUploadModule,
    TreeModule,
    ButtonModule,
    DragulaModule.forRoot(),
    ChartsModule,
    CalendarModule,
    GrowlModule
  ],
  providers: [
    LoggedInGuard,
    UserService
  ],
  declarations: [
    KifvsFrontendCliAppComponent,
    LoginComponent,
    DashboardComponent,
    DashboardComponent,
    FoliodetailComponent,
    FoliolistComponent,
    FoliofinalComponent,
    ArticlelistComponent,
    FolioarticleComponent,
    CompetenceComponent,
    CompetencelistComponent,
    CompetencedetailComponent,
    ExportComponent,
    ProductComponent,
    SystemComponent,
    DocumentComponent,
    DocumentdetailComponent,
    FolioComponent,
    AppcontentComponent,
    WmvlistComponent,
    AppbuttonlistComponent,
    SettingsComponent,
    DataprivacyComponent,
    ImprintComponent,
    NameTakenPipe,
    FolioTypePipe,
    MINMAX_DIRECTIVES
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [KifvsFrontendCliAppComponent],
})

export class AppModule {
  constructor(
    private dragulaService: DragulaService
  ) {
    dragulaService.createGroup('first-bag', {
      invalid: (el, handle) => el.classList.contains('donotdrag')
    });
  }
}
